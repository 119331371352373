import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import kalasikLogo from '../assests/KalasikLogo.png'
import { Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import te from '../assests/KalasikLogo.png'
import ContactUsPage from '../pages/ContactUsPage';
import AboutUsPageModal from '../pages/AboutUsPageModal';
import MenuIcon from '@mui/icons-material/Menu';

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};
const bthStyNavbar = {
    color: 'black',
    padding: 1,
    margin: 1,
    border: '2px solid #edb82a00',
    '&:hover': {
        border: '2px solid #edb82a'
    },
    '&:active': {
        borderBottom: '2px solid #edb82a'
    },

}
const bthStyaiNavbar = {
    color: 'white',
    backgroundColor: '#eeb92b',
    padding: 1,
    margin: 1,
    border: '2px solid #edb82a00',
    '&:hover': {
        border: '2px solid #edb82a'
    },
    '&:active': {
        borderBottom: '2px solid #edb82a'
    },

}
const drawerWidth = 240;
export default function NavBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>

            <Link to='/' style={{ textDecoration: 'none', color: '#534635' }}>
                <img src={kalasikLogo} width={80} />

                <Typography variant="h6" sx={{ my: 2 }}>
                    KALASIK
                </Typography>
            </Link>

            <Divider />
            <List>
                <Link to='/aiLobby' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center', backgroundColor: '#eeb92bs' }}>
                            <ListItemText primary='AI Lobby' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>

                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Home' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding sx={{
                        display: 'flex',
                        justifyContent: 'center',  // Center horizontally
                        alignItems: 'center',     // Center vertically
                    }}>

                        <AboutUsPageModal />
                    </ListItem>
                </Link>
                <Link to='/CbcMainPage' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='CBC' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/hustlerPlaybook' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Hustlers playbook' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/skills' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Skills' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                {/* <Link to='/solarSchool' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Solar Schools' />
                        </ListItemButton>
                    </ListItem>
                </Link> */}


                <ListItem disablePadding sx={{
                    display: 'flex',
                    justifyContent: 'center',  // Center horizontally
                    alignItems: 'center',     // Center vertically
                }}>
                    <ContactUsPage />

                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <React.Fragment >
            <CssBaseline />
            <ElevationScroll {...props} id="navbar">
                <AppBar sx={{ backgroundColor: 'transparent', color: '#eeb92b', p: 1 }}>
                    <Toolbar>
                        <Link to='/'>
                            <img src={kalasikLogo} style={{ maxWidth: '70px', marginLeft: '4.5vw' }}></img>
                        </Link>

                        <Link to='/' style={{ color: '#eeb92b', textDecoration: 'none' }}>

                            <Typography sx={{ fontSize: '2.5vw', marginLeft: 1, fontWeight: 'bold', fontFamily: 'Oswald' }}>KALASIK</Typography>
                        </Link>
                        <Stack sx={{ marginLeft: '5vw' }} direction={'row'} spacing={2} justifyContent={'right'} width={'90%'}>
                            <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                <Link to='/aiLobby'><Button sx={bthStyaiNavbar}>Ai Lobby</Button></Link>

                                <Link to='/'><Button sx={bthStyNavbar}>Home</Button></Link>
                                <AboutUsPageModal />
                                {/* <Link to='/solarSchool'><Button sx={bthStyNavbar}>Solar Schools</Button></Link> */}
                                <Link to='/CbcMainPage'><Button sx={bthStyNavbar}>CBC</Button></Link>

                                <Link to='/hustlerPlaybook'><Button sx={bthStyNavbar}>Hustler Playbook</Button></Link>
                                <Link to='/skills'><Button sx={bthStyNavbar}>Skills</Button></Link>
                                {/* <Link to='/hustlerPlaybook'><Button sx={bthStyNavbar}>Contact Us</Button></Link> */}
                                <ContactUsPage />
                            </Box>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, color: 'black', justifyContent: 'right', display: { md: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Stack>

                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Toolbar />
        </React.Fragment>
    );
}
