import React, { useEffect, useState } from 'react';
import '../App.css';
import NavBar from '../components/NavBar';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';


function AiTeacher() {

    return (

        <div className="App">

            <NavBar />
            <Stack sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, height: '85vh' }} flexDirection={{ xs: 'column', md: 'row' }}>
                <iframe src='https://interfaces.zapier.com/embed/chatbot/clrnsepky000v4mcbnq01q0df' height='100%' width='100%' allow='clipboard-write *' style={{ border: '0', padding: '0', margin: 'auto' }}></iframe>
            </Stack>

        </div >

    );
}

export default AiTeacher;
