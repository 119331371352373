import React from 'react';
import NavBar from '../components/NavBar';
import { Stack, Typography } from '@mui/material';
import solarBg from '../assests/solarBg.png';
import OurDesign from '../components/OurDesign';

const containerStyle = {
    background: `url(${solarBg}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    height: '100vh', // Set the height to cover the entire viewport
};

export default function SolarSchool() {
    const youtubeVideoUrl = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'; // Replace with your YouTube video link

    return (
        <div>
            <NavBar />
            <div style={containerStyle}>
                {/* Add your Course Material section here */}

            </div>
            <Stack alignItems={'center'} justifyContent={'center'} spacing={12}>
                <Typography variant="h4">Solar School</Typography>
                <iframe
                    width="640"
                    height="360"
                    src={youtubeVideoUrl}
                    title="YouTube Video"
                    frameborder="0"
                    allowFullScreen={true}
                ></iframe>
                <Typography width={'80vw'}>
                    Solar Schools, ingeniously constructed
                    within repurposed shipping containers,
                    are redefining education through a
                    fusion of sustainability and technology.
                    These adaptable classrooms, powered
                    by rooftop solar panels, offer a green
                    and reliable energy source, ensuring
                    uninterrupted learning in even the most
                    remote areas. Inside, students benefit
                    from modern amenities, including smart
                    TVs, cooling fans, and Starlink
                    connectivity, fostering a dynamic and
                    connected educational experience.
                    These versatile containers can
                    accommodate up to 20 students in a simple
                    model, but their scalability allows for the
                    creation of larger schools with capacities of
                    up to 500 students. In a world where
                    education and environmental responsibility
                    are paramount, Solar Container Schools
                    stand as beacons of innovation, illuminating
                    a path to a brighter and more equitable
                    future for learners everywhere.</Typography>
            </Stack>
            <Stack width={'70vw'} margin={'auto'}>
                <Typography variant="h4" textAlign={'center'}>Our Designs</Typography>
                <OurDesign />
            </Stack>

        </div>
    );
}
