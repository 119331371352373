import rulerBG from '../assests/rulerBG.png'
import agriIcon from '../assests/agriIcon.png'
import arabicIcon from '../assests/arabic.png'
import artIcon from '../assests/artIcon.png'
import eng from '../assests/eng.png'
import french from '../assests/french.png'
import german from '../assests/german.png'

import bible from '../assests/bible.png'

import CircularProgress from '@mui/material/CircularProgress';
import comSciIcon from '../assests/comSciIcon.png'
import bsICon from '../assests/bsICon.png'
import chin from '../assests/chin.png'
import healthEdu from '../assests/healthEdu.png'
import hindu from '../assests/hindu.png'
import homeScience from '../assests/homeScience.png'
import lifeSkills from '../assests/life-skills.png'
import quran from '../assests/quran.png'
import signLanguage from '../assests/sign-language.png'
import swahili from '../assests/swahili.png'
import indegenous from '../assests/indegenous.png'
import math from '../assests/math.png'
import theatre from '../assests/theatre.png'
import socialStudies from '../assests/socialStudies.png'
import vArt from '../assests/vArt.png'
import intergratedSci from '../assests/intergratedSci.png'
import exercise from '../assests/exercise.png'
import music from '../assests/music.png'
import technical from '../assests/technicalpng.png'
import tScience from '../assests/tScience.png'

import ENGLISHG1 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 1.png'
import ENGLISHG2 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 2.png'
import ENGLISHG3 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 3.png'
import ENGLISHG4 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 4.png'
import ENGLISHG5 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 5.png'
import ENGLISHG6 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 6.png'
import ENGLISHG7 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 7.png'
import ENGLISHG8 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 8.png'
import ENGLISHG9 from '../assests/Cover Photos/Cover Photos/ENGLISH/ENGLISH GRADE 9.png'

import Indigenous1 from '../assests/Cover Photos/Cover Photos/Indigenous Languages/Indigenous Languages grade 1.png'
import Indigenous2 from '../assests/Cover Photos/Cover Photos/Indigenous Languages/Indigenous Languages grade 2.png'
import Indigenous3 from '../assests/Cover Photos/Cover Photos/Indigenous Languages/Indigenous Languages grade 3.png'

import kiswahil1 from '../assests/Cover Photos/Cover Photos/KISWAHILI/kiswahili Grade 1.png'
import kiswahil2 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 2.png'
import kiswahil3 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 3.png'
import kiswahil4 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 4.png'
import kiswahil5 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 5.png'
import kiswahil6 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 6.png'
import kiswahil7 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 7.png'
import kiswahil8 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 8.png'
import kiswahil9 from '../assests/Cover Photos/Cover Photos/KISWAHILI/Kiswahili Grade 9.png'


import Agriculture4 from '../assests/Cover Photos/Cover Photos/Agriculture/Agriculture grade 4.png'
import Agriculture5 from '../assests/Cover Photos/Cover Photos/Agriculture/Agriculture grade 5.png'
import Agriculture6 from '../assests/Cover Photos/Cover Photos/Agriculture/Agriculture grade 6.png'
import Agriculture7 from '../assests/Cover Photos/Cover Photos/Agriculture/Agriculture grade 7.png'
import Agriculture8 from '../assests/Cover Photos/Cover Photos/Agriculture/Agriculture grade 8.png'
import Agriculture9 from '../assests/Cover Photos/Cover Photos/Agriculture/Agriculture grade 9.png'


import ARABIC4 from '../assests/Cover Photos/Cover Photos/Arabic/ARABIC grade 4.png'
import ARABIC5 from '../assests/Cover Photos/Cover Photos/Arabic/ARABIC grade 5.png'
import ARABIC6 from '../assests/Cover Photos/Cover Photos/Arabic/ARABIC grade 6.png'
import ARABIC7 from '../assests/Cover Photos/Cover Photos/Arabic/ARABIC grade 7.png'
import ARABIC8 from '../assests/Cover Photos/Cover Photos/Arabic/ARABIC grade 8.png'
import ARABIC9 from '../assests/Cover Photos/Cover Photos/Arabic/ARABIC grade 9.png'

import Christian4 from '../assests/Cover Photos/Cover Photos/Christian Religious education/Christian religious education grade 4.png'
import Christian5 from '../assests/Cover Photos/Cover Photos/Christian Religious education/Christian religious education grade 5.png'
import Christian6 from '../assests/Cover Photos/Cover Photos/Christian Religious education/Christian religious education grade 6.png'
import Christian7 from '../assests/Cover Photos/Cover Photos/Christian Religious education/Christian religious education grade 7.png'
import Christian8 from '../assests/Cover Photos/Cover Photos/Christian Religious education/Christian religious education grade 8.png'
import Christian9 from '../assests/Cover Photos/Cover Photos/Christian Religious education/Christian religious education grade 9.png'

import FRENCH4 from '../assests/Cover Photos/Cover Photos/french/FRENCH grade 4.png'
import FRENCH5 from '../assests/Cover Photos/Cover Photos/french/fRENCH grade 5.png'
import FRENCH6 from '../assests/Cover Photos/Cover Photos/french/fRENCH grade 6.png'
import FRENCH7 from '../assests/Cover Photos/Cover Photos/french/fRENCH grade 7.png'
import FRENCH8 from '../assests/Cover Photos/Cover Photos/french/fRENCH grade 8.png'
import FRENCH9 from '../assests/Cover Photos/Cover Photos/french/fRENCH grade 9.png'

import GERMAN4 from '../assests/Cover Photos/Cover Photos/GERMAN/GERMAN grade 4.png'
import GERMAN5 from '../assests/Cover Photos/Cover Photos/GERMAN/GERMAN grade 5.png'
import GERMAN6 from '../assests/Cover Photos/Cover Photos/GERMAN/GERMAN grade 6.png'
import GERMAN7 from '../assests/Cover Photos/Cover Photos/GERMAN/GERMAN grade 7.png'
import GERMAN8 from '../assests/Cover Photos/Cover Photos/GERMAN/GERMAN grade 8.png'
import GERMAN9 from '../assests/Cover Photos/Cover Photos/GERMAN/GERMAN grade 9.png'

import HINDU4 from '../assests/Cover Photos/Cover Photos/Hindu Religious Education/HINDU religious education grade 4.png'
import HINDU5 from '../assests/Cover Photos/Cover Photos/Hindu Religious Education/HINDU religious education grade 5.png'
import HINDU6 from '../assests/Cover Photos/Cover Photos/Hindu Religious Education/HINDU religious education grade 6.png'
import HINDU7 from '../assests/Cover Photos/Cover Photos/Hindu Religious Education/HINDU religious education grade 7.png'
import HINDU8 from '../assests/Cover Photos/Cover Photos/Hindu Religious Education/HINDU religious education grade 8.png'
import HINDU9 from '../assests/Cover Photos/Cover Photos/Hindu Religious Education/HINDU religious education grade 9.png'

import Home4 from '../assests/Cover Photos/Cover Photos/Home Science/Home science grade 4.png'
import Home5 from '../assests/Cover Photos/Cover Photos/Home Science/Home science grade 5.png'
import Home6 from '../assests/Cover Photos/Cover Photos/Home Science/Home science grade 6.png'
import Home7 from '../assests/Cover Photos/Cover Photos/Home Science/Home science grade 7.png'
import Home8 from '../assests/Cover Photos/Cover Photos/Home Science/Home science grade 8.png'
import Home9 from '../assests/Cover Photos/Cover Photos/Home Science/Home science grade 9.png'

import BUSINESS7 from '../assests/Cover Photos/Cover Photos/Business Studies/BUSINESS7.png'
import BUSINESS8 from '../assests/Cover Photos/Cover Photos/Business Studies/BUSINESS8.png'
import BUSINESS9 from '../assests/Cover Photos/Cover Photos/Business Studies/BUSINESS9.png'

import COMPSCI8 from '../assests/Cover Photos/Cover Photos/CompSci/ComputerSci8.png'
import COMPSCI7 from '../assests/Cover Photos/Cover Photos/CompSci/ComputerSci7.png'


import PRETECH8 from '../assests/Cover Photos/Cover Photos/PreTech/preTech8.png'

import LIFESKILLS7 from '../assests/Cover Photos/Cover Photos/LifeSkills/LifeSkills7.png'


import Islamic4 from '../assests/Cover Photos/Cover Photos/Islamic Religious Education/islamic religious education grade 4.png'
import Islamic5 from '../assests/Cover Photos/Cover Photos/Islamic Religious Education/islamic religious education grade 5.png'
import Islamic6 from '../assests/Cover Photos/Cover Photos/Islamic Religious Education/islamic religious education grade 6.png'
import Islamic7 from '../assests/Cover Photos/Cover Photos/Islamic Religious Education/islamic religious education grade 7.png'
import Islamic8 from '../assests/Cover Photos/Cover Photos/Islamic Religious Education/islamic religious education grade 8.png'
import Islamic9 from '../assests/Cover Photos/Cover Photos/Islamic Religious Education/islamic religious education grade 9.png'

import MANDARIN4 from '../assests/Cover Photos/Cover Photos/MANDARIN/MANDARIN grade 4.png'
import MANDARIN5 from '../assests/Cover Photos/Cover Photos/MANDARIN/MANDARIN grade 5.png'
import MANDARIN6 from '../assests/Cover Photos/Cover Photos/MANDARIN/MANDARIN grade 6.png'
import MANDARIN7 from '../assests/Cover Photos/Cover Photos/MANDARIN/MANDARIN grade 7.png'
import MANDARIN8 from '../assests/Cover Photos/Cover Photos/MANDARIN/MANDARIN grade 8.png'
import MANDARIN9 from '../assests/Cover Photos/Cover Photos/MANDARIN/MANDARIN grade 9.png'

import Maths1 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 1.png'
import Maths2 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 2.png'
import Maths3 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 3.png'
import Maths4 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 4.png'
import Maths5 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 5.png'
import Maths6 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 6.png'
import Maths7 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 7.png'
import Maths8 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 8.png'
import Maths9 from '../assests/Cover Photos/Cover Photos/Maths/Maths Grade 9.png'

import SCIENCE4 from '../assests/Cover Photos/Cover Photos/SCIENCE/science grade 4.png'
import SCIENCE5 from '../assests/Cover Photos/Cover Photos/SCIENCE/science grade 5.png'
import SCIENCE6 from '../assests/Cover Photos/Cover Photos/SCIENCE/science grade 6.png'
import SCIENCE8 from '../assests/Cover Photos/Cover Photos/SCIENCE/science grade 8.png'
import SCIENCE9 from '../assests/Cover Photos/Cover Photos/SCIENCE/science grade 9.png'

import Social4 from '../assests/Cover Photos/Cover Photos/Social Science/Social science grade 4.png'
import Social5 from '../assests/Cover Photos/Cover Photos/Social Science/Social science grade 5.png'
import Social6 from '../assests/Cover Photos/Cover Photos/Social Science/Social science grade 6.png'
import Social7 from '../assests/Cover Photos/Cover Photos/Social Science/Social science grade 7.png'
import Social8 from '../assests/Cover Photos/Cover Photos/Social Science/Social science grade 8.png'
import Social9 from '../assests/Cover Photos/Cover Photos/Social Science/Social science grade 9.png'


import ENGLISHOutlineG1 from '../assests/ebook pdfs/CBC/Course Outlines/G1/ENGLISH GRADE 1 - Sheet1.pdf'
import INDIGENOUSOutlineG1 from '../assests/ebook pdfs/CBC/Course Outlines/G1/INDIGENOUS LANGUAGES GRADE 1 - Sheet1.pdf'
import KISWAHILIOutlineG1 from '../assests/ebook pdfs/CBC/Course Outlines/G1/KISWAHILI DARASA LA KWANZA - Sheet1.pdf'


import ENGLISHBookG1 from '../assests/ebook pdfs/CBC/G1/ENGLISH GRADE 1.pdf'
import INDIGENOUSBookG1 from '../assests/ebook pdfs/CBC/G1/INDIGENOUS LANGUAGES GRADE 1.pdf'
import KISWAHILIBookG1 from '../assests/ebook pdfs/CBC/G1/KISWAHILI Grade 1.pdf'


import ENGLISHOutlineG2 from '../assests/ebook pdfs/CBC/Course Outlines/G2/ENGLISH GRADE 2 - Sheet1.pdf'
import INDIGENOUSOutlineG2 from '../assests/ebook pdfs/CBC/Course Outlines/G2/INDIGENOUS LANGUAGES GRADE 2 - Sheet1.pdf'
import KISWAHILIOutlineG2 from '../assests/ebook pdfs/CBC/Course Outlines/G2/KISWAHILI DARASA LA PILI - Sheet1.pdf'


import ENGLISHBookG2 from '../assests/ebook pdfs/CBC/G2/ENGLISH GRADE 2.pdf'
import INDIGENOUSBookG2 from '../assests/ebook pdfs/CBC/G2/INDIGENOUS LANGUAGE GRADE 2.pdf'
import KISWAHILIBookG2 from '../assests/ebook pdfs/CBC/G2/KISWAHILI Grade 2.pdf'

import ENGLISHOutlineG3 from '../assests/ebook pdfs/CBC/Course Outlines/G3/ENGLISH GRADE 3 - Sheet1.pdf'
import INDIGENOUSOutlineG3 from '../assests/ebook pdfs/CBC/Course Outlines/G3/INDIGENOUS LANGUAGES GRADE 3 - Sheet1.pdf'
import KISWAHILIOutlineG3 from '../assests/ebook pdfs/CBC/Course Outlines/G3/KISWAHILI GRADE 3 - Sheet1.pdf'


import ENGLISHBookG3 from '../assests/ebook pdfs/CBC/G3/ENGLISH GRADE 3.pdf'
import INDIGENOUSBookG3 from '../assests/ebook pdfs/CBC/G3/INDIGENOUS LANGUAGES GRADE 3.pdf'
import KISWAHILIBookG3 from '../assests/ebook pdfs/CBC/G3/KISWAHILI GRADE 3.pdf'

import AgricultureGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/AGRICULTURE GRADE 4 - Sheet1.pdf';
import EnglishGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/ENGLISH GRADE 4 - Sheet1.pdf';
import ArabicGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/ARABIC GRADE 4 - Sheet1.pdf';
import ArtAndCraftGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/ART AND CRAFT GRADE 4 - Sheet1.pdf';
import ChristianReligiousEducationGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/CHRISTIAN RELIGIOUS EDUCATION GRADE 4 - Sheet1.pdf';
import FrenchGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/FRENCH GRADE 4 - Sheet1.pdf';
import GermanGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/GERMAN GRADE 4 - Sheet1.pdf';
import HinduReligiousEducationGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/HINDU RELIGIOUS EDUCATION GRADE 4 - Sheet1.pdf';
import HomeScienceGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/HOME SCIENCE GRADE 4 - Sheet1.pdf';
import IndigenousLanguageGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/INDIGENOUS LANGUAGE GRADE 4 - Sheet1.pdf';
import IslamicReligiousEducationGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/ISLAMIC RELIGION EDUCATION GRADE 4 - Sheet1.pdf';
import KenyaSignLanguageGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/KENYA SIGN LANGUAGE GRADE 4 - Sheet1.pdf';
import MandarinChineseGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/MANDARIN CHINESE GRADE 4 - Sheet1.pdf';
import MusicGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/MUSIC GRADE 4 - Sheet1.pdf';
import SocialStudiesGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/SOCIAL STUDIES GRADE 4 - Sheet1.pdf';
import KiswahiliGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/KISWAHILI DARASA LA NNE - Sheet1.pdf';
import MATHEMATICSGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/MATHEMATICS GRADE 4 - Sheet1.pdf';
import PEGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/PHYSICAL AND HEALTH EDUCATION GRADE 4 - Sheet1.pdf';
import SciandTechGrade4 from '../assests/ebook pdfs/CBC/Course Outlines/G4/SCIENCE AND TECHNOLOGY _ GRADE 4 - Sheet1.pdf';


import AgricultureBookGrade4 from '../assests/ebook pdfs/CBC/G4/Agriculture Grade 4.pdf'
import ArabicBookGrade4 from '../assests/ebook pdfs/CBC/G4/ARABIC GRADE 4.pdf'


import ArtBookGrade4 from '../assests/ebook pdfs/CBC/G4/Art And Crraft Grade 4.pdf'
import ChristianBookGrade4 from '../assests/ebook pdfs/CBC/G4/Christian Religious Education Grade 4.pdf'
import FrenchBookGrade4 from '../assests/ebook pdfs/CBC/G4/French Grade 4.pdf'
import GermanBookGrade4 from '../assests/ebook pdfs/CBC/G4/German Grade 4.pdf'
import HinduBookGrade4 from '../assests/ebook pdfs/CBC/G4/Hindu Religious Education grade 4.pdf'
import HomeBookGrade4 from '../assests/ebook pdfs/CBC/G4/Home Science Grade 4.pdf'
import IslamicBookGrade4 from '../assests/ebook pdfs/CBC/G4/Islamic Religious Education Grade 4.pdf'
import MandarinBookGrade4 from '../assests/ebook pdfs/CBC/G4/Mandarin Chinese Grade 4.pdf'
import MusicBookGrade4 from '../assests/ebook pdfs/CBC/G4/Music Grade 4.pdf'
import ScienceBookGrade4 from '../assests/ebook pdfs/CBC/G4/Science And Technology Grade 4.pdf'
import SocialBookGrade4 from '../assests/ebook pdfs/CBC/G4/Social Studies Grade 4.pdf'
import KiswahiliBookG4 from '../assests/ebook pdfs/CBC/G4/KISWAHILI GRADE 4.pdf'
import MATHBookG4 from '../assests/ebook pdfs/CBC/G4/MATHEMATICS GRADE 4.pdf'
import ENGLISHBookG4 from '../assests/ebook pdfs/CBC/G4/ENGLISH GRADE 4.pdf'
import SocialStudiesBookG4 from '../assests/ebook pdfs/CBC/G4/Social Studies Grade 4.pdf'



import AgricultureGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/AGRICULTURE GRADE 5 - Sheet1.pdf';
import EnglishGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/ENGLISH GRADE 5 - Sheet1.pdf';
import ArabicGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/ARABIC GRADE 5 - Sheet1.pdf';
import ArtAndCraftGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/ART AND CRAFT GRADE 5 - Sheet1.pdf';
import ChristianReligiousEducationGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/CHRISTIAN RELIGIOUS EDUCATION GRADE 5 - Sheet1.pdf';
import FrenchGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/FRENCH GRADE 5 - Sheet1.pdf';
import GermanGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/GERMAN GRADE 5 - Sheet1.pdf';
import HinduReligiousEducationGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/HINDU RELIGIOUS EDUCATION GRADE 5 - Sheet1.pdf';
import HomeScienceGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/HOME SCIENCE GRADE 5 - Sheet1.pdf';
import IndigenousLanguageGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/INDIGENOUS LANGUAGES Grade 5 - Sheet1.pdf';
import IslamicReligiousEducationGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/ISLAMIC RELIGIOUS EDUCATION GRADE 5 - Sheet1.pdf';
//import KenyaSignLanguageGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/KENYA SIGN LANGUAGE GRADE 5 - Sheet1.pdf';
import MandarinChineseGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/MANDARIN CHINESE GRADE 5 - Sheet1.pdf';
import MusicGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/MUSIC GRADE 5 - Sheet1.pdf';
import SocialStudiesGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/SOCIAL STUDIES GRADE 5 - Sheet1.pdf';
import KiswahiliGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/KISWAHILI DARASA LA 5 - Sheet1.pdf';
import MATHEMATICSGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/MATHEMATICS GRADE 5 - Sheet1.pdf';
import PEGrade5 from '../assests/ebook pdfs/CBC/Course Outlines/G5/PHYSICAL EDUCATION GRADE 5 - Sheet1.pdf';


import AgricultureBookGrade5 from '../assests/ebook pdfs/CBC/G5/AGRICULTURE GRADE 5.pdf'
// import ArtBookGrade5 from '../assests/ebook pdfs/CBC/G5/Art And Crraft Grade 5.pdf'
import ChristianReligiousEducationBookG5 from '../assests/ebook pdfs/CBC/G5/CRE GRADE 5.pdf'
import FrenchBookGrade5 from '../assests/ebook pdfs/CBC/G5/FRENCH GRADE 5.pdf'
import GermanBookGrade5 from '../assests/ebook pdfs/CBC/G5/GERMAN GRADE 5.pdf'
import HinduBookGrade5 from '../assests/ebook pdfs/CBC/G5/HINDU RELIGIOUS EDUCATION GRADE 5.pdf'
import HomeBookGrade5 from '../assests/ebook pdfs/CBC/G5/HOME SCIENCE GRADE 5.pdf'
import ENGLISHGrade5 from '../assests/ebook pdfs/CBC/G5/ENGLISH GRADE 5.pdf'
import ArabicBookGrade5 from '../assests/ebook pdfs/CBC/G5/ARABIC GRADE 5.pdf'
import KiswahiliBookG5 from '../assests/ebook pdfs/CBC/G5/KISWAHILI GRADE 5.pdf'
import mathBookG5 from '../assests/ebook pdfs/CBC/G5/MATHEMATICS GRADE 5.pdf'

import IslamicReligiousEducationBookG5 from '../assests/ebook pdfs/CBC/G5/ISLAMIC RELIGIOUS EDUCATION GRADE 5.pdf'
// import MandarinBookGrade5 from '../assests/ebook pdfs/CBC/G5/Mandarin Chinese Grade 5.pdf'
// import MusicBookGrade5 from '../assests/ebook pdfs/CBC/G5/Music Grade 5.pdf'
// import ScienceBookGrade5 from '../assests/ebook pdfs/CBC/G5/Science And Technology Grade 5.pdf'
import SocialStudiesBookG5 from '../assests/ebook pdfs/CBC/G5/SOCIAL STUDIES GRADE 5.pdf'




import AgricultureGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/AGRICULTURE GRADE 6 - Sheet1.pdf';
import EnglishGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/ENGLISH GRADE 6 - Sheet1.pdf';
import ArabicGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/ARABIC GRADE 6 - Sheet1.pdf';
import ArtAndCraftGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/ART AND CRAFT GRADE 6 - Sheet1.pdf';
import ChristianReligiousEducationGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/CHRISTIAN RELIGIOUS EDUCATION GRADE 6 - Sheet1.pdf';
import FrenchGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/FRENCH GRADE 6 - Sheet1.pdf';
import GermanGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/GERMAN GRADE 6 - Sheet1.pdf';
import HinduReligiousEducationGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/HINDU RELIGIOUS EDUCATION GRADE 6 - Sheet1.pdf';
import HomeScienceGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/HOME SCIENCE GRADE 6 - Sheet1.pdf';
//import IndigenousLanguageGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/INDIGENOUS LANGUAGE GRADE 6 - Sheet1.pdf';
import IslamicReligiousEducationGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/ISLAMIC RELIGIOUS EDUCATION GRADE 6 - Sheet1.pdf';
//import KenyaSignLanguageGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/KENYA SIGN LANGUAGE GRADE 6 - Sheet1.pdf';
import MandarinChineseGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/MANDARIN CHINESE GRADE 6 - Sheet1.pdf';
import MusicGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/MUSIC GRADE 6 - Sheet1.pdf';
import SocialStudiesGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/SOCIAL STUDIES GRADE 6 - Sheet1.pdf';
import KiswahiliGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/KISWAHILI GRADE 6 - Sheet1.pdf';
import MATHEMATICSGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/MATHEMATICS GRADE 6 - Sheet1.pdf';
import ScienceGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/SCIENCE AND TECHNOLOGY GRADE 6 - Sheet1.pdf';


import AgricultureBookGrade6 from '../assests/ebook pdfs/CBC/G6/AGRICULTURE GRADE 6.pdf'
//import ArtBookGrade6 from '../assests/ebook pdfs/CBC/G6/Art And Crraft Grade 6.pdf'
import ChristianBookGrade6 from '../assests/ebook pdfs/CBC/G6/CHRISTIAN RELIGIOUS EDUCATION GRADE 6.pdf'
import FrenchBookGrade6 from '../assests/ebook pdfs/CBC/G6/FRENCH GRADE 6.pdf'
import GermanBookGrade6 from '../assests/ebook pdfs/CBC/G6/GERMAN GRADE 6.pdf'
import HinduBookGrade6 from '../assests/ebook pdfs/CBC/G6/HINDU RELIGIOUS EDUCATION GRADE 6.pdf'
import HomeBookGrade6 from '../assests/ebook pdfs/CBC/G6/HOME SCIENCE GRADE 6.pdf'
import ArabicBookGrade6 from '../assests/ebook pdfs/CBC/G6/ARABIC GRADE 6.pdf'
import EnglishBookGrade6 from '../assests/ebook pdfs/CBC/G6/ENGLISH GRADE 6.pdf'
import IslamicBookGrade6 from '../assests/ebook pdfs/CBC/G6/ISLAMIC RELIGIOUS EDUCATION GRADE 6.pdf'
import MandarinBookGrade6 from '../assests/ebook pdfs/CBC/G6/MANDARIN CHINESE GRADE 6.pdf'
//import MusicBookGrade6 from '../assests/ebook pdfs/CBC/G6/Music Grade 6.pdf'
import ScienceBookGrade6 from '../assests/ebook pdfs/CBC/G6/SCIENCE AND TECHNOLOGY GRADE 6.pdf'
//import SocialBookGrade6 from '../assests/ebook pdfs/CBC/G6/Social Studies Grade 6.pdf'
import MathGrade6 from '../assests/ebook pdfs/CBC/G6/MATHEMATICS GRADE 6.pdf'



import AgricultureGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/Agriculture GRADE 7 - Sheet1.pdf';
import EnglishGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/ENGLISH GRADE 7 - Sheet1.pdf';
import ArabicGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/ARABIC GRADE 7 - Sheet1.pdf';
//import ArtAndCraftGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/ART AND CRAFT GRADE 6 - Sheet1.pdf';
import ChristianReligiousEducationGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/CHRISTIAN RELIGIOUS EDUCATION GRADE 7 - Sheet1.pdf';
import FrenchGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/FRENCH GRADE 7 - Sheet1.pdf';
import GermanGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/GERMAN GRADE 7 - Sheet1.pdf';
import HinduReligiousEducationGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/HINDU RELIGIOUS EDUCATION GRADE 7 - Sheet1.pdf';
import HomeScienceGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/HOME SCIENCE GRADE 7 - Sheet1.pdf';
import IndigenousLanguageGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/INDIGENOUS GRADE 7 - Sheet1.pdf';
import IslamicReligiousEducationGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/ISLAMIC RELIGIOUS EDUCATION GRADE 7 - Sheet1.pdf';
import KenyaSignLanguageGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/KENYA SIGN LANGUAGE GRADE 7 - Sheet1.pdf';
import MandarinChineseGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/MANDARIN CHINESE GRADE 7 - Sheet1.pdf';
//import MusicGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/MUSIC GRADE 6 - Sheet1.pdf';
import SocialStudiesGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/SOCIAL STUDIES GRADE 7 - Sheet1.pdf';
import KiswahiliGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/KISWAHILI DARASA LA SABA - Sheet1.pdf';
import MATHEMATICSGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/MATHEMATICS GRADE 7 - Sheet1.pdf';
//import ScienceGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/SCIENCE AND TECHNOLOGY GRADE 6 - Sheet1.pdf';
import BusinessStudiesGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/BUSINESS STUDIES GRADE 7 - Sheet1.pdf';
import CompSciGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/COMPUTER SCIENCE GRADE 7 - Sheet1.pdf';
import HealthEduGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/HEALTH EDUCATION GRADE 7 - Sheet1.pdf';
import LifeSkillsGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/LIFE SKILLS GRADE 7 - Sheet1.pdf';
import PerformingArtsGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/PERFORMING ARTS GRADE 7 - Sheet1.pdf';
import VisualArtsGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/VISUAL ARTS GRADE 7 - Sheet1.pdf';
//import InterSciGrade7 from '../assests/ebook pdfs/CBC/Course Outlines/G7/VISUAL ARTS GRADE 7 - Sheet1.pdf';

import AgricultureBookGrade7 from '../assests/ebook pdfs/CBC/G7/Copy of Grade 7 Agriculture..pdf'
import VisualArtsBookGrade7 from '../assests/ebook pdfs/CBC/G7/VISUAL ARTS 7.pdf'
import IndigenousBookGrade7 from '../assests/ebook pdfs/CBC/G7/Indigenous Languages_ Grade 7.pdf'
import CompSciBookGrade7 from '../assests/ebook pdfs/CBC/G7/COMPUTER SCIENCE GRADE 7.pdf'
import performingArtsBookGrade7 from '../assests/ebook pdfs/CBC/G7/PERFORMING ARTS GRADE 7.pdf'
import LifeSkillsBookGrade7 from '../assests/ebook pdfs/CBC/G7/LIFE SKILL GRADE 7.pdf'
import ArabicBookGrade7 from '../assests/ebook pdfs/CBC/G7/ARABIC GRADE 7.pdf'
import EngBookGrade7 from '../assests/ebook pdfs/CBC/G7/ENGLISH GRADE 7 - Google Docs.pdf'
import BusStudiesBookGrade7 from '../assests/ebook pdfs/CBC/G7/Copy of BUSINESS STUDIES GRADE 7-1.pdf'
//import ArtBookGrade6 from '../assests/ebook pdfs/CBC/G6/Art And Crraft Grade 6.pdf'
import ChristianBookGrade7 from '../assests/ebook pdfs/CBC/G7/CHRISTIAN RELIGIOUS EDUCATION GRADE 7.pdf'
import FrenchBookGrade7 from '../assests/ebook pdfs/CBC/G7/FRENCH GRADE 7.pdf'
import GermanBookGrade7 from '../assests/ebook pdfs/CBC/G7/GERMAN GRADE 7.pdf'
import HinduBookGrade7 from '../assests/ebook pdfs/CBC/G7/HINDU RELIGIOUS EDUCATION GRADE 7.pdf'
import HomeBookGrade7 from '../assests/ebook pdfs/CBC/G7/HOME SCIENCE GRADE 7.pdf'
import IslamicBookGrade7 from '../assests/ebook pdfs/CBC/G7/ISLAMIC RELIGIOUS EDUCATION GRADE 7.pdf'
import MandarinBookGrade7 from '../assests/ebook pdfs/CBC/G7/Copy of MANDARIN CHINESE GRADE 7.pdf'
//import MusicBookGrade6 from '../assests/ebook pdfs/CBC/G6/Music Grade 6.pdf'
//import ScienceBookGrade6 from '../assests/ebook pdfs/CBC/G6/SCIENCE AND TECHNOLOGY GRADE 6.pdf'
import SocialBookGrade7 from '../assests/ebook pdfs/CBC/G7/Copy of SOCIAL STUDIES GRADE 7.pdf'
import MathGradeBook7 from '../assests/ebook pdfs/CBC/G7/Copy of MATHEMATICS GRADE 7.pdf'
import HealthEduBookGrade7 from '../assests/ebook pdfs/CBC/G7/HEALTH EDUCATION_ GRADE 7 - Google Docs.pdf'
import InterSciBookGrade7 from '../assests/ebook pdfs/CBC/G7/CBC integrated science.pdf'


import AgricultureGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/AGRICULTURE GRADE 8 - Sheet1.pdf';
import EnglishGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/ENGLISH GRADE 8 - Sheet1.pdf';
import ArabicGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/ARABIC GRADE 8 - Sheet1.pdf';
//import ArtAndCraftGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/ART AND CRAFT GRADE 6 - Sheet1.pdf';
import ChristianReligiousEducationGrade8 from '../assests/ebook pdfs/CBC/G8/CHRISTIAN RELIGIOUS EDUCATION GRADE 8 - Sheet1.pdf';
import FrenchGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/FRENCH GRADE 8 - Sheet1.pdf';
//import GermanGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/GERMAN GRADE 7 - Sheet1.pdf';
import HinduReligiousEducationGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/HINDU RELIGIOUS EDUCATION GRADE 8 - Sheet1.pdf';
import HomeScienceGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/HOME SCIENCE GRADE 8 - Sheet1.pdf';
import IndigenousLanguageGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/INDIGENIOUS LANGUAGE GRADE 8 - Sheet1.pdf';
import IslamicReligiousEducationGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/ISLAMIC RELIGIOUS EDUCATION GRADE 8 - Sheet1.pdf';
import KenyaSignLanguageGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/KENYA SIGN LANGUAGE GRADE 8 - Sheet1.pdf';
import MandarinChineseGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/MANDARIN CHINESE GRADE 8 - Sheet1.pdf';
//import MusicGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/MUSIC GRADE 6 - Sheet1.pdf';
import SocialStudiesGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/SOCIAL STUDIES GRADE 8 - Sheet1.pdf';
import KiswahiliGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/KISWAHILI GRADE 8 - Sheet1.pdf';
import PreTechStudiesGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/PRE-TECHNICAL STUDIES GRADE 8 - Sheet1.pdf';
import MATHEMATICSGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/MATHEMATICS GRADE 8 - Sheet1.pdf';
//import ScienceGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/SCIENCE AND TECHNOLOGY GRADE 6 - Sheet1.pdf';
import BusinessStudiesGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/BUSINESS STUDIES GRADE 8 - Sheet1.pdf';
import CompSciGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/COMPUTER SCIENCE GRADE 8 - Sheet1.pdf';
//import HealthEduGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/HEALTH EDUCATION GRADE 7 - Sheet1.pdf';
//import LifeSkillsGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/LIFE SKILLS GRADE 7 - Sheet1.pdf';
import PerformingArtsGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/PERFORMING ARTS GRADE 8 - Sheet1.pdf';
import VisualArtsGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/VISUAL ARTS GRADE 8 - Sheet1.pdf';
import InterSciGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/INTERGRATED SCIENCE GRADE 8 - Sheet1.pdf';
import PEGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G8/PHYSICAL EDUCATION AND SPORTS GRADE 8 - Sheet1.pdf';


import AgricultureBookGrade8 from '../assests/ebook pdfs/CBC/G8/Agriculture Grade 8.pdf'
import VisualArtsBookGrade8 from '../assests/ebook pdfs/CBC/G8/Visual Arts Grade 8.pdf'
import IndigenousBookGrade8 from '../assests/ebook pdfs/CBC/G8/Indigenous Language Grade 8.pdf'
import CompSciBookGrade8 from '../assests/ebook pdfs/CBC/G8/Computer Science Grade 8.pdf'
// import performingArtsBookGrade8 from '../assests/ebook pdfs/CBC/G8/PERFORMING ARTS GRADE 7.pdf'
// import LifeSkillsBookGrade8 from '../assests/ebook pdfs/CBC/G8/LIFE SKILL GRADE 7.pdf'
import ArabicBookGrade8 from '../assests/ebook pdfs/CBC/G8/Arabic Grade 8.pdf'
import EngBookGrade8 from '../assests/ebook pdfs/CBC/G8/English Grade 8.pdf'
import BusStudiesBookGrade8 from '../assests/ebook pdfs/CBC/G8/Business Studies Grade 8.pdf'
import PreTechStudiesBookGrade8 from '../assests/ebook pdfs/CBC/G8/PRE-TECHNICAL STUDIES- GRADE 8 - Google Docs.pdf'
//import ArtBookGrade6 from '../assests/ebook pdfs/CBC/G6/Art And Crraft Grade 6.pdf'
import ChristianReligiousEducationBOOKGrade8 from '../assests/ebook pdfs/CBC/G8/CRE GRADE 8.pdf'
import FrenchBookGrade8 from '../assests/ebook pdfs/CBC/G8/FRENCH GRADE 8 - Google Docs.pdf'
import GermanBookGrade8 from '../assests/ebook pdfs/CBC/G8/GERMAN GRADE 8 - Google Docs.pdf'
import HinduBookGrade8 from '../assests/ebook pdfs/CBC/G8/HINDU RELIGIOUS EDUCATION GRADE8 - Google Docs.pdf'
import HomeBookGrade8 from '../assests/ebook pdfs/CBC/G8/HOME SCIENCE GRADE 8 - Google Docs.pdf'
import IslamicBookGrade8 from '../assests/ebook pdfs/CBC/G8/ISLAMIC RELIGIOUS EDUCATION GRADE 8 - Google Docs.pdf'
import MandarinBookGrade8 from '../assests/ebook pdfs/CBC/G8/MANDARIN CHINESE_GRADE 8 - Google Docs.pdf'
//import MusicBookGrade6 from '../assests/ebook pdfs/CBC/G6/Music Grade 6.pdf'
//import ScienceBookGrade6 from '../assests/ebook pdfs/CBC/G6/SCIENCE AND TECHNOLOGY GRADE 6.pdf'
import SocialBookGrade8 from '../assests/ebook pdfs/CBC/G8/SOCIAL STUDIES GRADE 8 - Google Docs.pdf'
import SwahiliBookGrade8 from '../assests/ebook pdfs/CBC/G8/Kiswahili Grade 8.pdf'
import InterSciBookGrade8 from '../assests/ebook pdfs/CBC/G8/INTERGRATED SCIENCE GRADE 8 - Google Docs.pdf'
import MathGrade8 from '../assests/ebook pdfs/CBC/G8/MATHEMATICS GRADE 8 - Google Docs.pdf'


import AgricultureGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/AGRICULTURE GRADE 9 - Sheet1.pdf';
import EnglishGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/ENGLISH GRADE 9 - Sheet1.pdf';
import ArabicGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/ARABIC GRADE 9 - Sheet1.pdf';
//import ArtAndCraftGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/ART AND CRAFT GRADE 6 - Sheet1.pdf';
//import ChristianReligiousEducationGrade9 from '../assests/ebook pdfs/CBC/CHRISTIAN RELIGIOUS EDUCATION GRADE 9 - Sheet1.pdf';
import FrenchGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/FRENCH GRADE 9 - Sheet1.pdf';
import GermanGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/GERMAN GRADE 9 - Sheet1.pdf';
import HinduReligiousEducationGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/HINDU RELIGIOUS EDUCATION GRADE 9 - Sheet1.pdf';
import HomeScienceGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/HOME SCIENCE GRADE 9 - Sheet1.pdf';
import IndigenousLanguageGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/INDIGENOUS LANGUAGES GRADE 9 - Sheet1.pdf';
import IslamicReligiousEducationGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/ISLAMIC RELIGIOUS EDUCATION GRADE 9 - Sheet1.pdf';
//import KenyaSignLanguageGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/KENYA SIGN LANGUAGE GRADE 8 - Sheet1.pdf';
import MandarinChineseGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/MANDARIN CHINESE GRADE 9 - Sheet1.pdf';
//import MusicGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/MUSIC GRADE 6 - Sheet1.pdf';
import SocialStudiesGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/SOCIAL STUDIES GRADE 9 - Sheet1.pdf';
import KiswahiliGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/KISWAHILI GREDI YA 9 - Sheet1.pdf';
import PreTechStudiesGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/PRE-TECHNICAL STUDIES GRADE 9 - Sheet1.pdf';
import MATHEMATICSGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/Mathematics Grade 9 - Sheet1.pdf';
//import ScienceGrade6 from '../assests/ebook pdfs/CBC/Course Outlines/G6/SCIENCE AND TECHNOLOGY GRADE 6 - Sheet1.pdf';
import BusinessStudiesGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/BUSINESS STUDIES GRADE 9 - Sheet1.pdf';
import CompSciGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/COMPUTER SCIENCE GRADE 9 - Sheet1.pdf';
//import HealthEduGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G9/HEALTH EDUCATION GRADE 7 - Sheet1.pdf';
//import LifeSkillsGrade8 from '../assests/ebook pdfs/CBC/Course Outlines/G9/LIFE SKILLS GRADE 7 - Sheet1.pdf';
import PerformingArtsGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/PERFORMING ARTS GRADE 9 - Sheet1.pdf';
import VisualArtsGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/VISUAL ARTS GRADE 9 - Sheet1.pdf';
import InterSciGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/INTEGRATED SCIENCE GRADE 9 - Sheet1.pdf';
import PEGrade9 from '../assests/ebook pdfs/CBC/Course Outlines/G9/Sports and Physical Education Grade 9 - Sheet1.pdf';

import AgricultureBookGrade9 from '../assests/ebook pdfs/CBC/G9/AGRICULTURE GRADE 9.pdf'
//import IndigenousBookGrade9 from '../assests/ebook pdfs/CBC/G9/Indigenous Language Grade 9.pdf'
import CompSciBookGrade9 from '../assests/ebook pdfs/CBC/G9/COMPUTER SCIENCE GRADE 9 - Google Docs.pdf'
// import performingArtsBookGrade9 from '../assests/ebook pdfs/CBC/G9/PERFORMING ARTS GRADE 9.pdf'
// import LifeSkillsBookGrade9 from '../assests/ebook pdfs/CBC/G9/LIFE SKILL GRADE 9.pdf'
import ArabicBookGrade9 from '../assests/ebook pdfs/CBC/G9/ARABIC GRADE 9.pdf'
import EngBookGrade9 from '../assests/ebook pdfs/CBC/G9/ENGLISH GRADE 9.pdf'
import BusStudiesBookGrade9 from '../assests/ebook pdfs/CBC/G9/BUSINESS STUDIES GRADE 9.pdf'
//import ArtBookGrade6 from '../assests/ebook pdfs/CBC/G6/Art And Crraft Grade 6.pdf'
import ChristianBookGrade9 from '../assests/ebook pdfs/CBC/G9/CHRISTIAN RELIGIOUS EDUCATION GRADE 9 - Google Docs.pdf'
import FrenchBookGrade9 from '../assests/ebook pdfs/CBC/G9/FRENCH GRADE 9.pdf'
import GermanBookGrade9 from '../assests/ebook pdfs/CBC/G9/GERMAN GRADE 9.pdf'
import HinduBookGrade9 from '../assests/ebook pdfs/CBC/G9/HINDU RELIGIOUS EDUCATION GRADE 9.pdf'
import HomeBookGrade9 from '../assests/ebook pdfs/CBC/G9/HOME SCIENCE GRADE 9.pdf'
import IslamicBookGrade9 from '../assests/ebook pdfs/CBC/G9/ISLAMIC RELIGIOUS EDUCATION GRADE 9 - Google Docs.pdf'
import MandarinBookGrade9 from '../assests/ebook pdfs/CBC/G9/MANDARIN CHINESE GRADE 9.pdf'
//import MusicBookGrade6 from '../assests/ebook pdfs/CBC/G6/Music Grade 6.pdf'
//import ScienceBookGrade6 from '../assests/ebook pdfs/CBC/G6/SCIENCE AND TECHNOLOGY GRADE 6.pdf'
import SocialBookGrade9 from '../assests/ebook pdfs/CBC/G9/SOCIAL STUDIES GRADE 9 - Google Docs.pdf'

import InterSciBookGrade9 from '../assests/ebook pdfs/CBC/G9/INTEGRATED SCIENCE GRADE 9.pdf'
import MATHEMATICSBookGrade9 from '../assests/ebook pdfs/CBC/G9/MATHEMATICS GRADE 9.pdf'
import BussinessBookGrade9 from '../assests/ebook pdfs/CBC/G9/BUSINESS STUDIES GRADE 9 - Google Docs.pdf'
import PreTechBookGrade9 from '../assests/ebook pdfs/CBC/G9/PRE-TECHNICAL AND -PRE-CAREER EDUCATION GRADE 9 - Google Docs.pdf'




const subjectWiseObjectList = {
    1: [
        { subId: 0, subName: "English", subIcon: eng, subCover: ENGLISHG1, subOutline: ENGLISHOutlineG1, subBook: ENGLISHBookG1 },
        { subId: 1, subName: "Indigenous Languages", subIcon: indegenous, subCover: Indigenous1, subOutline: INDIGENOUSOutlineG1, subBook: INDIGENOUSBookG1 },
        { subId: 2, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil1, subOutline: KISWAHILIOutlineG1, subBook: KISWAHILIBookG1 }
    ],
    2: [
        { subId: 0, subName: "English", subIcon: eng, subCover: ENGLISHG2, subOutline: ENGLISHOutlineG2, subBook: ENGLISHBookG2 },
        { subId: 1, subName: "Indigenous Languages", subIcon: indegenous, subCover: Indigenous2, subOutline: INDIGENOUSOutlineG2, subBook: INDIGENOUSBookG2 },
        { subId: 2, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil2, subOutline: KISWAHILIOutlineG2, subBook: KISWAHILIBookG2 }
    ],
    3: [
        { subId: 0, subName: "English", subIcon: eng, subCover: ENGLISHG3, subOutline: ENGLISHOutlineG3, subBook: ENGLISHBookG3 },
        { subId: 1, subName: "Indigenous Languages", subIcon: indegenous, subCover: Indigenous3, subOutline: INDIGENOUSOutlineG3, subBook: INDIGENOUSBookG3 },
        { subId: 2, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil3, subOutline: KISWAHILIOutlineG3, subBook: KISWAHILIBookG3 }
    ],
    4: [
        { subId: 0, subName: "Agriculture", subIcon: agriIcon, subCover: Agriculture4, subOutline: AgricultureGrade4, subBook: AgricultureBookGrade4 },
        { subId: 1, subName: "Arabic", subIcon: arabicIcon, subCover: ARABIC4, subOutline: ArabicGrade4, subBook: ArabicBookGrade4 },
        //missing sub cover
        { subId: 2, subName: "Art and Craft", subIcon: artIcon, subOutline: ArtAndCraftGrade4, subBook: ArtBookGrade4 },
        { subId: 3, subName: "Christian Religious Education", subIcon: bible, subCover: Christian4, subOutline: ChristianReligiousEducationGrade4, subBook: ChristianBookGrade4 },
        //missing book
        { subId: 4, subName: "English", subIcon: eng, subCover: ENGLISHG4, subOutline: EnglishGrade4, subBook: ENGLISHBookG4 },
        { subId: 5, subName: "French", subIcon: french, subCover: FRENCH4, subOutline: FrenchGrade4, subBook: FrenchBookGrade4 },
        { subId: 6, subName: "German", subIcon: german, subCover: GERMAN4, subOutline: GermanGrade4, subBook: GermanBookGrade4 },
        { subId: 7, subName: "Hindu Religious Education", subIcon: hindu, subCover: HINDU4, subOutline: HinduReligiousEducationGrade4, subBook: HinduBookGrade4 },
        { subId: 8, subName: "Home Science", subIcon: homeScience, subCover: Home4, subOutline: HomeScienceGrade4, subBook: HomeBookGrade4 },

        //missing cover
        //{ subId: 9, subName: "Indigenous Language", subIcon: indegenous, subCover: Home4, subOutline: IndigenousLanguageGrade4, subBook: IndigenousLanguageBookG4 },
        { subId: 10, subName: "Islamic Religious Education", subIcon: quran, subCover: Islamic4, subOutline: IslamicReligiousEducationGrade4, subBook: IslamicBookGrade4 },

        //missing cover
        { subId: 11, subName: "Kenya Sign Language", subIcon: signLanguage, subOutline: KenyaSignLanguageGrade4 },


        { subId: 12, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil4, subOutline: KiswahiliGrade4, subBook: KiswahiliBookG4 },

        { subId: 13, subName: "Mandarin Chinese", subIcon: chin, subCover: MANDARIN4, subOutline: MandarinChineseGrade4, subBook: MandarinBookGrade4 },
        //missing book
        { subId: 14, subName: "Mathematics", subIcon: math, subCover: Maths4, subOutline: MATHEMATICSGrade4, subBook: MATHBookG4 },
        //missing subcove 
        { subId: 15, subName: "Music", subIcon: music, subOutline: MusicGrade4 },
        //missing subCover,outline and book
        { subId: 16, subName: "Physical and Health Education (PHE)", subIcon: exercise, subOutline: PEGrade4 },
        //missing outline
        { subId: 17, subName: "Science and Technology", subIcon: intergratedSci, subCover: SCIENCE4, subOutline: SciandTechGrade4, subBook: ScienceBookGrade4 },
        { subId: 18, subName: "Social Studies", subIcon: socialStudies, subCover: Social4, subOutline: SocialStudiesGrade4, subBook: SocialStudiesBookG4 },
    ],
    5: [

        { subId: 0, subName: "Agriculture", subIcon: agriIcon, subCover: Agriculture5, subOutline: AgricultureGrade5, subBook: AgricultureBookGrade5 },
        //missing book
        { subId: 1, subName: "Arabic", subIcon: arabicIcon, subCover: ARABIC5, subOutline: ArabicGrade5, subBook: ArabicBookGrade5 },
        //missing book
        { subId: 2, subName: "Art and Craft", subIcon: artIcon, subOutline: ArtAndCraftGrade5, subBook: ENGLISHBookG3 },
        //missing book
        { subId: 3, subName: "Christian Religious Education", subIcon: bible, subCover: Christian5, subOutline: ChristianReligiousEducationGrade5, subBook: ChristianReligiousEducationBookG5 },
        { subId: 4, subName: "English", subIcon: eng, subCover: ENGLISHG5, subOutline: EnglishGrade5, subBook: ENGLISHGrade5 },
        { subId: 5, subName: "French", subIcon: french, subCover: FRENCH5, subOutline: FrenchGrade5, subBook: FrenchBookGrade5 },
        { subId: 6, subName: "German", subIcon: german, subCover: GERMAN5, subOutline: GermanGrade5, subBook: GermanBookGrade5 },
        { subId: 7, subName: "Hindu Religious Education", subIcon: hindu, subCover: HINDU5, subOutline: HinduReligiousEducationGrade5, subBook: HinduBookGrade5 },
        //missing book
        { subId: 8, subName: "Home Science", subIcon: homeScience, subCover: Home5, subOutline: HomeScienceGrade5, subBook: HomeBookGrade5 },
        //missing book,cover
        //{ subId: 9, subName: "Indigenous Languages", subIcon: indegenous, subOutline: IndigenousLanguageGrade5 },
        //missing book
        { subId: 10, subName: "Islamic Religious Education", subIcon: quran, subCover: Islamic5, subOutline: IslamicReligiousEducationGrade5, subBook: IslamicReligiousEducationBookG5 },
        //missing book
        { subId: 11, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil5, subOutline: KiswahiliGrade5, subBook: KiswahiliBookG5 },
        //missing book
        { subId: 12, subName: "Mandarin Chinese", subIcon: chin, subCover: MANDARIN5, subOutline: MandarinChineseGrade5, subBook: ENGLISHBookG3 },
        //missing book
        { subId: 13, subName: "Mathematics", subIcon: math, subCover: Maths5, subOutline: MATHEMATICSGrade5, subBook: mathBookG5 },
        //missing book,cover
        { subId: 14, subName: "Music", subIcon: music, subOutline: MusicGrade5 },
        //missing book,cover,outline
        { subId: 15, subName: "Physical Education", subIcon: exercise, subOutline: PEGrade5 },
        //missing book
        { subId: 16, subName: "Social Studies", subIcon: socialStudies, subCover: Social5, subOutline: SocialStudiesGrade5, subBook: SocialStudiesBookG5 },
    ],
    6: [
        { subId: 0, subName: "Agriculture", subIcon: agriIcon, subCover: Agriculture6, subOutline: AgricultureGrade6, subBook: AgricultureBookGrade6 },
        //missing book
        { subId: 1, subName: "Arabic", subIcon: arabicIcon, subCover: ARABIC6, subOutline: ArabicGrade6, subBook: ArabicBookGrade6 },
        //missing book
        { subId: 2, subName: "Art and Craft", subIcon: artIcon, subOutline: ArtAndCraftGrade6 },
        { subId: 3, subName: "Christian Religious Education", subIcon: bible, subCover: Christian6, subOutline: ChristianReligiousEducationGrade6, subBook: ChristianBookGrade6 },
        //missing book
        { subId: 4, subName: "English", subIcon: eng, subCover: ENGLISHG6, subOutline: EnglishGrade6, subBook: EnglishBookGrade6 },
        { subId: 5, subName: "French", subIcon: french, subCover: FRENCH6, subOutline: FrenchGrade6, subBook: FrenchBookGrade6 },
        { subId: 6, subName: "German", subIcon: german, subCover: GERMAN6, subOutline: GermanGrade6, subBook: GermanBookGrade6 },
        { subId: 7, subName: "Hindu Religious Education", subIcon: hindu, subCover: HINDU6, subOutline: HinduReligiousEducationGrade6, subBook: HinduBookGrade6 },
        //missing book
        { subId: 8, subName: "Home Science", subIcon: homeScience, subCover: Home6, subOutline: HomeScienceGrade6, subBook: HomeBookGrade6 },
        //missing book
        { subId: 9, subName: "Islamic Religious Education", subIcon: quran, subCover: Islamic6, subOutline: IslamicReligiousEducationGrade6, subBook: IslamicBookGrade6 },
        //missing book
        { subId: 10, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil6, subOutline: KiswahiliGrade6 },
        { subId: 11, subName: "Mandarin Chinese", subIcon: chin, subCover: MANDARIN6, subOutline: MandarinChineseGrade6, subBook: MandarinBookGrade6 },
        { subId: 12, subName: "Mathematics", subIcon: math, subCover: Maths6, subOutline: MATHEMATICSGrade6, subBook: MathGrade6 },
        //missing cover,book
        { subId: 13, subName: "Music", subIcon: music, subOutline: MusicGrade6 },
        //missing cover,book,outline 
        { subId: 14, subName: "Physical Health", subIcon: exercise },

        { subId: 15, subName: "Science and Technology", subIcon: tScience, subCover: SCIENCE6, subOutline: ScienceGrade6, subBook: ScienceBookGrade6 },

        //missing book
        { subId: 16, subName: "Social Studies", subIcon: socialStudies, subCover: Social6, subOutline: SocialStudiesGrade6 },
    ],
    7: [
        { subId: 0, subName: "Agriculture", subIcon: agriIcon, subCover: Agriculture7, subOutline: AgricultureGrade7, subBook: AgricultureBookGrade7 },
        { subId: 1, subName: "Arabic", subIcon: arabicIcon, subCover: ARABIC7, subOutline: ArabicGrade7, subBook: ArabicBookGrade7 },
        //missing cover
        { subId: 2, subName: "Business Studies", subIcon: bsICon, subCover: BUSINESS7, subOutline: BusinessStudiesGrade7, subBook: BusStudiesBookGrade7 },
        { subId: 3, subName: "Christian Religious Education", subIcon: bible, subCover: Christian7, subOutline: ChristianReligiousEducationGrade7, subBook: ChristianBookGrade7 },
        //missing cover
        { subId: 4, subName: "Computer Science", subIcon: comSciIcon, subOutline: CompSciGrade7, subBook: CompSciBookGrade7, subBook: COMPSCI7 },
        { subId: 5, subName: "English", subIcon: eng, subCover: ENGLISHG7, subOutline: EnglishGrade7, subBook: EngBookGrade7 },
        { subId: 6, subName: "French", subIcon: french, subCover: FRENCH7, subOutline: FrenchGrade7, subBook: FrenchBookGrade7 },
        { subId: 7, subName: "German", subIcon: german, subCover: GERMAN7, subOutline: GermanGrade7, subBook: GermanBookGrade7 },
        //missing cover and book
        { subId: 8, subName: "Health Education", subIcon: healthEdu, subOutline: HealthEduGrade7, subBook: HealthEduBookGrade7 },
        { subId: 9, subName: "Hindu Religious Education", subIcon: hindu, subCover: HINDU7, subOutline: HinduReligiousEducationGrade7, subBook: HinduBookGrade7 },
        { subId: 10, subName: "Home Science", subIcon: homeScience, subCover: Home7, subOutline: HomeScienceGrade7, subBook: HomeBookGrade7 },
        //missing cover
        { subId: 11, subName: "Indigenous", subIcon: indegenous, subOutline: IndigenousLanguageGrade7, subBook: IndigenousBookGrade7 },
        { subId: 12, subName: "Islamic Religious Education", subIcon: quran, subCover: Islamic7, subOutline: IslamicReligiousEducationGrade7, subBook: IslamicBookGrade7 },
        //missing book and cover
        { subId: 13, subName: "Kenya Sign Language", subIcon: signLanguage, subOutline: KenyaSignLanguageGrade7 },
        //missing book
        { subId: 14, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil7, subOutline: KiswahiliGrade7 },
        //missing cover
        { subId: 15, subName: "Life Skills", subIcon: lifeSkills, subOutline: LifeSkillsGrade7, subBook: LifeSkillsBookGrade7, subCover: LIFESKILLS7 },
        { subId: 16, subName: "Mandarin Chinese", subIcon: chin, subCover: MANDARIN7, subOutline: MandarinChineseGrade7, subBook: MandarinBookGrade7 },
        //missing book
        { subId: 17, subName: "Mathematics", subIcon: math, subCover: Maths7, subOutline: MATHEMATICSGrade7, subBook: MathGradeBook7 },
        //missing cover
        { subId: 18, subName: "Performing Arts", subIcon: theatre, subOutline: PerformingArtsGrade7, subBook: performingArtsBookGrade7 },
        { subId: 19, subName: "Social Studies", subIcon: socialStudies, subCover: Social7, subOutline: SocialStudiesGrade7, subBook: SocialBookGrade7 },
        //missing cover
        { subId: 20, subName: "Visual Arts", subIcon: vArt, subOutline: VisualArtsGrade7, subBook: VisualArtsBookGrade7 },
        //missing cover and course outline and book
        { subId: 21, subName: "Intergrated Science", subIcon: intergratedSci, subCover: Social7, subOutline: SocialStudiesGrade7, subBook: InterSciBookGrade7 },
    ],
    8: [
        { subId: 0, subName: "Agriculture", subIcon: agriIcon, subCover: Agriculture8, subOutline: AgricultureGrade8, subBook: AgricultureBookGrade8 },
        { subId: 1, subName: "Arabic", subIcon: arabicIcon, subCover: ARABIC8, subOutline: ArabicGrade8, subBook: ArabicBookGrade8 },
        //missing cover
        { subId: 2, subName: "Business Studies", subIcon: bsICon, subCover: BUSINESS8, subOutline: BusinessStudiesGrade8, subBook: BusStudiesBookGrade8 },
        //missing book
        { subId: 3, subName: "Christian Religious Education", subIcon: bible, subCover: Christian8, subOutline: ChristianReligiousEducationGrade8, subBook: ChristianReligiousEducationBOOKGrade8 },
        //missing cover
        { subId: 4, subName: "Computer Science", subIcon: comSciIcon, subOutline: CompSciGrade8, subBook: CompSciBookGrade8, subCover: COMPSCI8 },
        { subId: 5, subName: "English", subIcon: eng, subCover: ENGLISHG8, subOutline: EnglishGrade8, subBook: EngBookGrade8 },
        //missing book
        { subId: 6, subName: "French", subIcon: french, subCover: FRENCH8, subOutline: FrenchGrade8, subBook: FrenchBookGrade8 },
        { subId: 6, subName: "German", subIcon: german, subCover: GERMAN8, subOutline: GermanBookGrade8, subBook: GermanBookGrade8 },
        //missing book
        { subId: 7, subName: "Hindu Religious Education", subIcon: hindu, subCover: HINDU8, subOutline: HinduReligiousEducationGrade8, subBook: HinduBookGrade8 },
        { subId: 8, subName: "Home Science", subIcon: homeScience, subCover: Home4, subCover: Home8, subOutline: HomeScienceGrade8, subBook: HomeBookGrade8 },
        //missing cover
        { subId: 9, subName: "Indigenous Language", subIcon: indegenous, subOutline: IndigenousLanguageGrade8, subBook: IndigenousBookGrade8 },
        //missing cover
        { subId: 10, subName: "Integrated Science", subIcon: intergratedSci, subOutline: InterSciGrade8, subBook: InterSciBookGrade8 },
        //missing book
        { subId: 11, subName: "Islamic Religious Education", subIcon: quran, subCover: Islamic8, subOutline: IslamicReligiousEducationGrade8, subBook: IslamicBookGrade8 },
        //missing book
        { subId: 12, subName: "Kenya Sign Language", subIcon: signLanguage, subOutline: KenyaSignLanguageGrade8 },
        { subId: 13, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil8, subOutline: KiswahiliGrade8, subBook: SwahiliBookGrade8 },
        //missing book
        { subId: 14, subName: "Mandarin Chinese", subIcon: chin, subCover: MANDARIN8, subOutline: MandarinChineseGrade8, subBook: MandarinBookGrade8 },
        //missing book
        { subId: 15, subName: "Mathematics", subIcon: math, subCover: Maths8, subOutline: MATHEMATICSGrade8, subBook: MathGrade8 },
        //missing book and cover
        { subId: 16, subName: "Performing Arts", subIcon: theatre, subOutline: PerformingArtsGrade8 },
        //missing book and cover
        { subId: 17, subName: "Physical Education and Sports", subIcon: exercise, subOutline: PEGrade8 },
        //missing cover
        { subId: 18, subName: "Pre-Technical Studies", subIcon: technical, subOutline: PreTechStudiesGrade8, subBook: PreTechStudiesBookGrade8, subCover: PRETECH8 },
        //missing cover
        { subId: 19, subName: "Social Studies", subIcon: socialStudies, subOutline: SocialStudiesGrade8, subBook: SocialBookGrade8, subCover: Social8 },
        //missing cover
        { subId: 20, subName: "Visual Arts", subIcon: vArt, subOutline: VisualArtsGrade8, subBook: VisualArtsBookGrade8 },
    ],
    9: [
        { subId: 0, subName: "Agriculture", subIcon: agriIcon, subCover: Agriculture9, subOutline: AgricultureGrade9, subBook: AgricultureBookGrade9 },
        { subId: 1, subName: "Arabic", subIcon: arabicIcon, subCover: ARABIC9, subOutline: ArabicGrade9, subBook: ArabicBookGrade9 },
        { subId: 2, subName: "Business Studies", subIcon: bsICon, subCover: BUSINESS9, subOutline: BusinessStudiesGrade9, subBook: BussinessBookGrade9 },
        { subId: 3, subName: "Christian Religious Education", subIcon: bible, subCover: Christian9, subBook: ChristianBookGrade9 },
        //missing cover
        { subId: 4, subName: "Computer Science", subIcon: comSciIcon, subOutline: CompSciGrade9, subBook: CompSciBookGrade9 },
        { subId: 5, subName: "English", subIcon: eng, subCover: ENGLISHG9, subOutline: EnglishGrade9, subBook: EngBookGrade9 },
        { subId: 6, subName: "French", subIcon: french, subCover: FRENCH9, subOutline: FrenchGrade9, subBook: FrenchBookGrade9 },
        { subId: 7, subName: "German", subIcon: german, subCover: GERMAN9, subOutline: GermanGrade9, subBook: GermanBookGrade9 },
        { subId: 8, subName: "Hindu Religious Education", subIcon: hindu, subCover: HINDU9, subOutline: HinduReligiousEducationGrade9, subBook: HinduBookGrade9 },
        { subId: 9, subName: "Home Science", subIcon: homeScience, subCover: Home9, subOutline: HomeScienceGrade9, subBook: HomeBookGrade9 },
        //missing cover
        { subId: 10, subName: "Indigenous Languages", subIcon: indegenous, subOutline: IndigenousLanguageGrade9 },
        //missing cover
        { subId: 11, subName: "Integrated Science", subIcon: intergratedSci, subOutline: InterSciGrade9, subBook: InterSciBookGrade9 },
        { subId: 12, subName: "Islamic Religious Education", subIcon: quran, subCover: Islamic9, subOutline: IslamicReligiousEducationGrade9, subBook: IslamicBookGrade9 },
        { subId: 13, subName: "Kiswahili", subIcon: swahili, subCover: kiswahil9, subOutline: KiswahiliGrade9 },
        { subId: 14, subName: "Mandarin Chinese", subIcon: chin, subCover: MANDARIN9, subOutline: MandarinChineseGrade9, subBook: MandarinBookGrade9 },
        { subId: 15, subName: "Mathematics", subIcon: math, subCover: Maths9, subOutline: MATHEMATICSGrade9, subBook: MATHEMATICSBookGrade9 },
        //missing cover and book
        { subId: 16, subName: "Performing Arts", subIcon: theatre, subOutline: PerformingArtsGrade9 },
        //missing cover
        { subId: 17, subName: "Pre-Technical Studies", subIcon: technical, subOutline: PreTechStudiesGrade9, subBook: PreTechBookGrade9 },
        { subId: 18, subName: "Social Studies", subIcon: socialStudies, subCover: Social9, subOutline: SocialStudiesGrade9, subBook: SocialBookGrade9 },
        //missing cover and bool
        { subId: 19, subName: "Sports and Physical Education", subIcon: exercise, subOutline: PEGrade9 },
        //missing cover
        { subId: 20, subName: "Visual Arts", subIcon: vArt, subOutline: VisualArtsGrade9 },
    ]
}
export default subjectWiseObjectList;