import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function AiCards({ cardTitle, cardDescription, cardImage, cardLink }) {
    return (
        <Link to='/aiSinglePage' state={{ cardLink: cardLink }} style={{ textDecoration: 'none' }}>
            <Card sx={{ maxWidth: 350, margin: 'auto', minHeight: '100%' }}>
                <CardMedia
                    sx={{ height: 300 }}
                    image={cardImage}
                    title={cardTitle}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {cardTitle}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {cardDescription}
                    </Typography>
                </CardContent>
                {/* <CardActions>
                <Button size="small">Share</Button>
                <Button size="small">Learn More</Button>
            </CardActions> */}
            </Card>
        </Link >
    );
}