import { Box, IconButton, Paper, Stack, Typography, Grid } from '@mui/material'
import React, { useState, useEffect } from "react";
import TwoIcon from '../assests/TwoIcon.png'
import ThreeIcon from '../assests/ThreeIcon.png'
import FourIcon from '../assests/FourIcon.png'
import FiveIcon from '../assests/FiveIcon.png'
import SixIcon from '../assests/SixIcon.png'
import SevenIcon from '../assests/SevenIcon.png'
import EightIcon from '../assests/EightIcon.png'
import NineIcon from '../assests/NineIcon.png'
import TenIcon from '../assests/TenIcon.png'
import ElevenIcon from '../assests/ElevenIcon.png'
import TwelveIcon from '../assests/TwelveIcon.png'

import OneIcon from '../assests/OneIcon.png'
import fileIcon from '../assests/fileIcon.png'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import SubjectModal from '../pages/SubjectModal'
import { useNavigate } from "react-router-dom";
//import './CBCMenuItem.css'
const gradeList = [
    {
        "id": 1,
        "name": "Grade 1",
        "icon": OneIcon
    },
    {
        "id": 2,
        "name": "Grade 2",
        "icon": TwoIcon
    },
    {
        "id": 3,
        "name": "Grade 3",
        "icon": ThreeIcon
    },
    {
        "id": 4,
        "name": "Grade 4",
        "icon": FourIcon
    },
    {
        "id": 5,
        "name": "Grade 5",
        "icon": FiveIcon
    },
    {
        "id": 6,
        "name": "Grade 6",
        "icon": SixIcon
    },
    {
        "id": 7,
        "name": "Grade 7",
        "icon": SevenIcon
    },
    {
        "id": 8,
        "name": "Grade 8",
        "icon": EightIcon
    },
    {
        "id": 9,
        "name": "Grade 9",
        "icon": NineIcon
    }
    // ,
    // {
    //     "id": 9,
    //     "name": "Grade 10",
    //     "icon": TenIcon
    // },
    // {
    //     "id": 10,
    //     "name": "Grade 11",
    //     "icon": ElevenIcon
    // },
    // {
    //     "id": 11,
    //     "name": "Grade 12",
    //     "icon": TwelveIcon
    // }
]

export default function CBCMenuItem() {
    const navigate = useNavigate();



    return (

        <Paper
            sx={{
                background: '#495492',
                //background: 'linear-gradient(90deg, rgba(122, 139, 235, 0.47) 3.21%, rgba(220, 223, 238, 0.00) 99.16%)',
                borderRadius: '20px',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backdropFilter: 'blur(26px)'
            }}
            onClick={() => { navigate('/CbcMainPage') }}
        >
            <Stack>
                <Box>
                    <Typography
                        sx={{
                            color: 'white',
                            fontSize: { xs: 20, md: 35 },
                            fontWeight: 'bold',
                            fontFamily: 'Oswald',
                            padding: 1 
                        }}
                    >
                        CBC
                    </Typography>
                </Box>
                {/* <Box

                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'center',
                        margin: '5px'
                    }}
                >
                    {gradeList.map((item, index) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={item.name} iconUrl={item.icon} />
                        </div>
                    ))}
                </Box> */}
                <Grid container>
                    <Grid item xs={12} sx={{ margin: 0 }} justifyContent={'flex-start'}>
                        <Paper sx={{ width: '150px', backgroundColor: '#425262', color: 'white', padding: 0.5, borderBottomRightRadius: '20px', borderTopRightRadius: '20px', fontSize: '12px' }}>
                            Lower Primary
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[0].name} iconUrl={gradeList[0].icon} grdId={gradeList[0].id} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[1].name} iconUrl={gradeList[1].icon} grdId={gradeList[1].id} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[2].name} iconUrl={gradeList[2].icon} grdId={gradeList[2].id} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ margin: 0 }} justifyContent={'flex-start'}>
                        <Paper sx={{ width: '150px', backgroundColor: '#425262', color: 'white', padding: 0.5, borderBottomRightRadius: '20px', borderTopRightRadius: '20px', fontSize: '12px' }}>
                            Upper Primary
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[3].name} iconUrl={gradeList[3].icon} grdId={gradeList[3].id} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[4].name} iconUrl={gradeList[4].icon} grdId={gradeList[4].id} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[5].name} iconUrl={gradeList[5].icon} grdId={gradeList[5].id} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ margin: 0 }} justifyContent={'flex-start'}>
                        <Paper sx={{ width: '150px', backgroundColor: '#425262', color: 'white', padding: 0.5, borderBottomRightRadius: '20px', borderTopRightRadius: '20px', fontSize: '12px' }}>
                            Lower Secondary
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[6].name} iconUrl={gradeList[6].icon} grdId={gradeList[6].id} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[7].name} iconUrl={gradeList[7].icon} grdId={gradeList[7].id} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <SubjectModal subName={gradeList[8].name} iconUrl={gradeList[8].icon} grdId={gradeList[8].id} />
                        </div>
                    </Grid>
                </Grid>
            </Stack>
            <div onClick={(e) => e.stopPropagation()}>
                <IconButton
                    onClick={() => { navigate('/CbcMainPage') }}
                    sx={{
                        backgroundColor: '#fec107',
                        width: '50px',
                        height: '50px',
                        alignSelf: 'flex-end',
                        marginBottom: '10px'
                    }}
                >
                    <KeyboardDoubleArrowDownIcon sx={{ color: 'white' }} />
                </IconButton>
            </div>
        </Paper >

    )
}
