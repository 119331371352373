import React from 'react';
import { Route } from 'react-router-dom';

function generateSitemap() {
    const routes = [
        '/',
        '/hustlerPlaybook',
        '/aboutUs',
        '/solarSchool',
        '/CoursePage',
        '/CbcMainPage',
        '/MemberValidationComponent',
        '/pdf-display',
        '/pdf-display-ebook',
        '/skills',
        '/aiTeacher',
    ];

    const domain = 'https://kalasik.com'; // Replace with your actual domain

    const urls = routes.map(route => {
        return `<url><loc>${domain}${route}</loc></url>`;
    });

    const sitemap =
        `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${urls.join('\n')}
    </urlset>`;

    return sitemap;
}

const Sitemap = () => {
    const sitemap = generateSitemap();

    return <div>{sitemap}</div>;
};

export default Sitemap;
