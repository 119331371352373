import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab, TextField, Grid, Backdrop, CircularProgress } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import emailjs from 'emailjs-com';
import axios from 'axios'; // Import axios if not already imported

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const fabStyle = {
    position: 'fixed',
    top: '200px',
    right: '16px',
};
const downloadPDF = (pdfUrl, callback) => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'document.pdf'; // You can customize the downloaded file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (callback && typeof callback === 'function')
    {
        callback();
    }
};
export default function PaymentModal({ ct, pdf }) {
    const formRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);
    const handleDownload = () => {
        downloadPDF(pdf, afterDownloadComplete);
    };

    const afterDownloadComplete = () => {
        // Your action after the download completes
        console.log('Download completed. Perform additional action here.');
        
        if (formRef.current)
        {
            formRef.current.reset();
        }
        handleClose()
    };
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const firstName = e.target.firstName.value;
    //     const lastName = e.target.lastName.value;
    //     const email = e.target.email.value;
    //     const phoneNumber = e.target.phoneNumber.value; // New phone number field
    //     const title = 'Your Course Title'; // Replace with your actual course title
    //     const price = '$99.99'; // Replace with your actual course price

    //     emailjs
    //         .send(
    //             'your_email_service_id',
    //             'your_email_template_id',
    //             {
    //                 first_name: firstName,
    //                 last_name: lastName,
    //                 email: email,
    //                 phoneNumber: phoneNumber, // Include phone number
    //                 title: title,
    //                 price: price,
    //             },
    //             'your_email_user_id'
    //         )
    //         .then(
    //             (response) => {
    //                 console.log('Email sent successfully:', response);
    //                 handleClose();
    //             },
    //             (error) => {
    //                 console.error('Email sending failed:', error);
    //             }
    //         );
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const firstName = e.target.firstName.value;
        const lastName = e.target.lastName.value;
        const email = e.target.email.value;
        const phoneNumber = e.target.phoneNumber.value;
        const title = 'Your Course Title';
        const price = '$99.99';
        const callbackURL = 'https://f6q32q8l-8080.uks1.devtunnels.ms/callback';
        // Your existing emailjs code remains unchanged

        try
        {
            // Call the backend API to initiate STK push
            const response = await fetch('http://localhost:8080/mpesa/stkpush', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    title,
                    price,
                    callbackURL
                }),
            });

            if (response.ok)
            {
                setLoading(false);
                // Handle success, you can also handle the response data if needed
                console.log('Payment initiation successful:', response);

            } else
            {
                setLoading(false);
                // Handle error
                console.error('Payment initiation failed:', response.statusText);
            }

            //handleClose(); // Close the modal after the API call
        } catch (error)
        {
            console.error('Error during payment initiation:', error);
            // Handle the error as needed
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen} >
                Download
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Make a Payment
                    </Typography>
                    <form onSubmit={handleSubmit} ref={formRef}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    label="First Name"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastName"
                                    label="Last Name"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    type="email"
                                    label="Email"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="phoneNumber"
                                    label="Phone Number" // New phone number field
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Course: {ct}
                                </Typography>
                                <Typography variant="subtitle1">
                                    Price: KE100
                                </Typography>
                            </Grid>
                        </Grid>
                        <a download style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Button variant="contained" color="primary" onClick={handleDownload}>
                                Pay Now
                            </Button>
                        </a>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
