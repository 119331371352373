import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab, TextField, Grid, Stack, Card, CardContent, CardActions, Icon, Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import emailjs from 'emailjs-com';
import te from '../assests/aboutUsVid.mp4'
import filePDFicon from '../assests/filePDFicon.png'
import solar from '../assests/solar-panel.png'
import lipaMpesa from '../assests/lipaMpesa.png'
import tc from '../assests/tc.pdf'
import solarpdf from '../assests/ss.pdf'
import kalasikpdf from '../assests/k.pdf'
import kalasikLogo from '../assests/KalasikLogo.png'
import ClearIcon from '@mui/icons-material/Clear';
const style = {
    position: 'absolute',
    top: { xs: '110%', md: '65%' },
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100vw', md: '80vw' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const fabStyle = {
    position: 'fixed',
    top: '200px',
    right: '16px',
};
const bthStyNavbar = {
    color: 'black',
    padding: 1,
    margin: 1,
    border: '2px solid #edb82a00',
    '&:hover': {
        border: '2px solid #edb82a'
    },
    '&:active': {
        borderBottom: '2px solid #edb82a'
    },

}
export default function ContactUsPage() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const firstName = e.target.firstName.value;
        const lastName = e.target.lastName.value;
        const email = e.target.email.value; // New email field
        const message = e.target.message.value;

        emailjs
            .send(
                'your_email_service_id',
                'your_email_template_id',
                {
                    first_name: firstName,
                    last_name: lastName,
                    email: email, // Pass the email address
                    message: message,
                },
                'your_email_user_id'
            )
            .then(
                (response) => {
                    console.log('Email sent successfully:', response);
                    handleClose();
                },
                (error) => {
                    console.error('Email sending failed:', error);
                }
            );
    };

    return (
        <div>
            {/* <Fab onClick={handleOpen} color="secondary" aria-label="edit" sx={fabStyle}>
                <ContactMailRoundedIcon />
            </Fab> */}
            <Button onClick={handleOpen} sx={bthStyNavbar}>About Us</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll' }}
            >
                <Box sx={style}>
                    {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                        Contact Us
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    label="First Name"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastName"
                                    label="Last Name"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email" // New email field
                                    type="email"
                                    label="Email"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="message"
                                    label="Message"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </form> */}
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent={'center'}
                        alignContent={'center'}
                        mb={3}
                    >
                        <Box style={{ marginTop: '20px', background: 'white' }}>
                            <Stack direction={'row'} justifyContent={{ xs: 'space-between', sm: 'center' }}>
                                <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28, textAlign: 'center' }}>About Us</Typography>
                                <IconButton onClick={handleClose} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <ClearIcon></ClearIcon>
                                </IconButton>
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-around'} spacing={4} alignItems={'center'}>
                                <Box sx={{ width: { xs: '100%', sm: '45%' }, color: 'black' }} >
                                    {/* <img src={te} style={{ marginTop: 25, maxWidth: '150px', marginLeft: '8vw' }}></img> */}
                                    <video src={te} controls style={{ marginTop: 25, maxWidth: '100%', borderRadius: '10px', }}></video>

                                </Box>
                                <Box sx={{ width: { xs: '90%', md: '40%' }, color: 'black', }} >
                                    <Typography>

                                        <p style={{ fontSize: '16px', textAlign: 'center' }}>
                                            Nestled within the vibrant educational landscape of Kenya, Kalasik Education shines as a pioneering
                                            company dedicated to breaking down barriers to learning by offering free online educational content.
                                            Founded by a visionary team of educators and technologists, Kalasik Education has become a beacon
                                            of hope, transcending socio-economic disparities and empowering learners of all backgrounds.
                                        </p>
                                        <p style={{ textAlign: 'center', fontSize: '22px', fontWeight: '200' }}>
                                            Kalasik, building the steps to free education!
                                        </p>
                                        <p style={{ textAlign: 'center', background: 'rgb(238, 185, 43)', padding: '15px', borderRadius: '5px', fontSize: '22px', fontWeight: '200' }}>
                                            Donate and Support
                                        </p>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <img src={lipaMpesa} width='50%' style={{ margin: 'auto' }} />
                                        </div>
                                        {/* <p>
                                            Kalasik Education's core mission revolves around democratizing education. Leveraging the vast
                                            potential of the internet, they have curated a comprehensive library of top-notch educational
                                            materials that span a wide spectrum of subjects and skills. This treasure trove of knowledge is freely
                                            accessible to anyone with an internet connection, effectively erasing geographical boundaries and
                                            financial constraints. Through strategic partnerships with local schools and communities, Kalasik
                                            Education not only enhances traditional education but also instills a profound love for learning in
                                            countless Kenyan youths, opening doors to brighter prospects and a more promising future. As they
                                            continually expand their reach and impact, Kalasik Education proudly represents Kenya's capacity to
                                            drive positive change through the fusion of technology and education.
                                        </p> */}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>

                    </Stack>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={{ xs: 4, sm: 2, md: 4 }}
                        justifyContent={'center'}
                        alignContent={'center'}
                        mb={3}
                        divider={<Divider orientation={{ xs: "horizontal", md: "vertical" }} flexItem />}
                    >
                        <a href={kalasikpdf} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>

                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                <img src={kalasikLogo} width={'50px'}></img>
                                <Typography variant="h6" gutterBottom>Learn More About Kalasik</Typography>
                                {/* <Typography variant="body1">{project.description}</Typography> */}

                            </Stack>
                        </a>

                        <a href={solarpdf} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                            <Stack direction={{ xs: 'column-reverse', md: 'row' }} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                <img src={solar} width={'50px'}></img>
                                <Typography variant="h6" gutterBottom>Learn More About Solar Schools</Typography>
                                {/* <Typography variant="body1">{project.description}</Typography> */}

                            </Stack>
                        </a>
                    </Stack>
                    <Typography>

                    </Typography>
                    <div className="modal-footer" style={{ textAlign: 'center', margin: 'auto', width: '100%', fontSize: '12px' }}>
                        &copy; 2023 Innovate Mandate ltd. All rights reserved.
                        <p>
                            By using this site, you agree to our{' '}
                            <a href={tc} target="_blank" rel="noopener noreferrer">
                                Terms and Conditions &
                            </a>{' '}

                            <a href={tc} target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>
                            .
                        </p>
                    </div>
                </Box>

            </Modal >
        </div >
    );
}
