import React, { useEffect, useState } from 'react';
import '../App.css';
import NavBar from '../components/NavBar';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import AiCards from '../components/AiCards';

import AiEngImage from '../assests/AiCardImages/AiEngImage.png'
import AiKisImage from '../assests/AiCardImages/AiKisImage.png'

import Chef from '../assests/AiCardImages/4 Chef.png'
import _5 from '../assests/AiCardImages/5.png'
import AiTeacher from '../assests/AiCardImages/Ai Teacher.png'

import BodaBoda from '../assests/AiCardImages/boda boda.png'
import Business from '../assests/AiCardImages/Business.png'
import CareerGuidance from '../assests/AiCardImages/Career guidance.png'
import ChurchFriend from '../assests/AiCardImages/Church friend.png'
import Counsellor from '../assests/AiCardImages/counsellor.png'
import Doctor from '../assests/AiCardImages/Doctor.png'
import EnglishLanguageTeacher from '../assests/AiCardImages/english language teacher.png'
import FitnessCoach from '../assests/AiCardImages/fitness coach.png'
import Lawyer from '../assests/AiCardImages/lawyer.png'
import LifeCoach from '../assests/AiCardImages/Life coach.png'
import MamaMboga from '../assests/AiCardImages/mama mboga.png'
import Mechanic from '../assests/AiCardImages/Mechanic.png'
import Mentor from '../assests/AiCardImages/mentor.png'
import StoryTeller from '../assests/AiCardImages/story teller.png'
import SwahiliLanguageTeacher from '../assests/AiCardImages/swahili language teacher.png'
import TeacherSwahili from '../assests/AiCardImages/Teacher Swahili.png'


const aiCardData = [
    { id: 0, cardTitle: 'AI Lawyer', cardDescription: 'AI Lawyer Chatbot: Legal advice, document drafting, and consultation for your legal queries.', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3oss81000rz7tweg9xsax4', cardImage: Lawyer },
    { id: 1, cardTitle: 'AI Doctor', cardDescription: 'AI Doctor Chatbot: Instant medical advice, symptom analysis, and healthcare guidance for your well-being', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3oco2o002szs8afjou7dcl', cardImage: Doctor },
    { id: 2, cardTitle: 'AI Mechanic', cardDescription: 'AI Mechanic Chatbot: Vehicle diagnostics, maintenance tips, and repair guidance for smooth rides', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3ooyhj000p3goygqv4ofeo', cardImage: Mechanic },
    { id: 3, cardTitle: 'AI Business advisor', cardDescription: 'AI Business Advisor chatbot: Get expert guidance on strategies, finances, and operations tailored to your business needs, all in one place.', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3ndyuo0010vkbg1rd31gib', cardImage: Business },
    { id: 4, cardTitle: 'AI Counselor', cardDescription: 'AI Counselor Chatbot: Mental health support, coping strategies, and emotional wellness guidance', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3p5fl5002sq4opnlechxvn', cardImage: Counsellor },
    { id: 5, cardTitle: 'AI Mentor', cardDescription: 'AI Mentor Chatbot: Personalized guidance, skill development, and career insights for growth and success.', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3qlsvt000boogvynb7ijil', cardImage: Mentor },
    { id: 6, cardTitle: 'AI Chef', cardDescription: 'AI Chef chatbot: Cook smarter with personalized recipes, ingredient substitutions, and cooking tips for delicious meals anytime, anywhere', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3n0plx0015zs8aa4y1so26', cardImage: Chef },
    { id: 7, cardTitle: 'AI Mama Mboga advisor', cardDescription: 'Mama Mboga Advisor: Empower vegetable vendors with stock management, pricing advice, and market trends for prosperous businesses.', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3nishd000knu5s3tjzbkrz', cardImage: MamaMboga },
    { id: 8, cardTitle: 'AI BodaBoda Advisor', cardDescription: 'BodaBoda Advisor: Guide motorcycle taxi operators with route optimization, safety guidelines, and financial planning for successful journeys', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3nuysk001cq4op6toerhdj', cardImage: BodaBoda },
    { id: 9, cardTitle: 'AI Church Friend', cardDescription: 'AI Church Friend Chatbot: Spiritual support, prayer requests, and community connection for uplifting conversations and guidance.', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3rfsha000bma0gri2z22b8', cardImage: ChurchFriend },
    { id: 10, cardTitle: 'AI Story Teller ', cardDescription: 'AI Storyteller Chatbot: Personalized narratives, interactive storytelling, and imaginative adventures', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3pbpmf001ehbtbpwa7pj21', cardImage: StoryTeller },
    { id: 11, cardTitle: 'AI Fitness Coach', cardDescription: 'AI Fitness Coach Chatbot: Customized workout plans, nutrition advice, and motivation for a healthier lifestyle', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3pkdt700051264prgsch9f', cardImage: FitnessCoach },
    { id: 12, cardTitle: 'AI Career Guidance Coach', cardDescription: 'AI Career Guidance Coach Chatbot: Career assessment, job search tips, and professional development advice', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3qfcu9002ykq6tcnl0ffwx', cardImage: CareerGuidance },
    { id: 13, cardTitle: 'AI Life coach', cardDescription: 'AI Life Coach Chatbot: Goal setting, self-improvement techniques, and life balance strategies.', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3qj5oq000orf069dnlqoyv', cardImage: LifeCoach },
    { id: 14, cardTitle: 'AI English Language teacher', cardDescription: 'English Language Teacher: Enhance language skills with interactive lessons, grammar tips, and vocabulary building exercises."', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3o4h5y000h10ihimbaoqrj', cardImage: EnglishLanguageTeacher },
    { id: 15, cardTitle: 'AI Swahili Language Teacher', cardDescription: 'Kiswahili Language Teacher: Improve Swahili proficiency with engaging lessons, cultural insights, and language practice exercises.', cardLink: 'https://interfaces.zapier.com/embed/chatbot/cls3oa03z0005kq6tqn1tp234', cardImage: SwahiliLanguageTeacher },

]
function AiLobby() {

    return (

        <div className="App">

            <NavBar />
            <br></br>
            <Grid container direction="row" justifyContent="center" width={'100%'} spacing={{ xs: 5, md: 5 }} margin={'auto'}>
                <Grid container item xs={12} direction="row" justifyContent="center" spacing={5}>
                    <Grid item xs={10} md={4}>
                        <AiCards cardTitle='AI Teacher' cardDescription='An AI teacher chatbot offering personalized learning experiences, instant feedback, and interactive lessons across various subjects.' cardImage={AiEngImage} cardLink='https://interfaces.zapier.com/embed/chatbot/clrnsepky000v4mcbnq01q0df'></AiCards>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <AiCards cardTitle='AI Teacher (Kiswahili)' cardDescription='An AI teacher chatbot in Kiswahili, providing personalized learning, instant feedback, and interactive lessons tailored to individual needs.' cardImage={AiKisImage} cardLink='https://interfaces.zapier.com/embed/chatbot/cls3mxspt000hvkbg468qj5hk'></AiCards>
                    </Grid>
                </Grid>
                <Grid container item xs={10} direction="row" justifyContent="center" spacing={5}>
                    {
                        aiCardData.map((item) => (
                            <Grid item xs={12} md={4}>
                                <AiCards cardTitle={item.cardTitle} cardDescription={item.cardDescription} cardLink={item.cardLink} cardImage={item.cardImage}></AiCards>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </div >

    );
}

export default AiLobby;
