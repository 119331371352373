import React from 'react'
import NavBar from '../components/NavBar'
import { Box, Stack, Typography } from '@mui/material'
import te from '../assests/KalasikLogo.png'

export default function AboutUsPage() {
    return (
        <div>
            <div className='hConatiner'>
                <NavBar />
                <br />
                <br />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    justifyContent={'center'}
                    alignContent={'center'}
                    mb={6}
                >
                    <Box style={{ marginTop: '20px', width: '85vw', background: 'white', padding: 20 }}>
                        <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28, textAlign: 'center' }}>About Us</Typography>
                        <Stack direction={'row'} justifyContent={'space-around'} spacing={12} alignItems={'center'}>
                            <Box sx={{ width: '40%', color: 'black' }} >
                                <img src={te} style={{ marginTop: 25, maxWidth: '150px', marginLeft: '8vw' }}></img>

                            </Box>
                            <Box sx={{ width: '60%', color: 'black' }} >
                                <Typography>

                                    <p>
                                        Nestled within the vibrant educational landscape of Kenya, Kalasik Education shines as a pioneering
                                        company dedicated to breaking down barriers to learning by offering free online educational content.
                                        Founded by a visionary team of educators and technologists, Kalasik Education has become a beacon
                                        of hope, transcending socio-economic disparities and empowering learners of all backgrounds.
                                    </p>


                                    <p>
                                        Kalasik Education's core mission revolves around democratizing education. Leveraging the vast
                                        potential of the internet, they have curated a comprehensive library of top-notch educational
                                        materials that span a wide spectrum of subjects and skills. This treasure trove of knowledge is freely
                                        accessible to anyone with an internet connection, effectively erasing geographical boundaries and
                                        financial constraints. Through strategic partnerships with local schools and communities, Kalasik
                                        Education not only enhances traditional education but also instills a profound love for learning in
                                        countless Kenyan youths, opening doors to brighter prospects and a more promising future. As they
                                        continually expand their reach and impact, Kalasik Education proudly represents Kenya's capacity to
                                        drive positive change through the fusion of technology and education.
                                    </p>
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>

                </Stack>
            </div>
        </div>
    )
}
