import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import '../App.css'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Import Grid component from Material-UI
import { CardActions, Divider, InputAdornment, Stack, Tab, Tabs } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PaymentModal from './PaymentModal';
import BodaBodaBusinessGuideImage from '../assests/Cover Photos/Hustlers/bod boda business guide.png';
import CosmeticsRetailImage from '../assests/Cover Photos/Hustlers/cosmetics retail.png';
import ExecutiveBarberShopImage from '../assests/Cover Photos/Hustlers/executive barber shop.png';
import LPGGasImage from '../assests/Cover Photos/Hustlers/LPG Gas.png';
import MpesaAgentImage from '../assests/Cover Photos/Hustlers/mpesa agent.png';
import PlumbingHardwareImage from '../assests/Cover Photos/Hustlers/plumbing hardware.png';
import RunningALocalBarImage from '../assests/Cover Photos/Hustlers/running a local bar.png';
import TyreRetailImage from '../assests/Cover Photos/Hustlers/tyre retail.png';

import CarWashImage from '../assests/Cover Photos/Hustlers/car wash.png';
import CyberCafeImage from '../assests/Cover Photos/Hustlers/cyber cafe.png';
import GarageImage from '../assests/Cover Photos/Hustlers/garage.png';
import MedicalImagingImage from '../assests/Cover Photos/Hustlers/medical imaging.png';
import NailManufacturingImage from '../assests/Cover Photos/Hustlers/nail manufacturing.png';
import PrintingAndBrandingImage from '../assests/Cover Photos/Hustlers/printing and branding.png';
import SmokieMayaiImage from '../assests/Cover Photos/Hustlers/smokie mayai.png';
import WinesAndSpiritsImage from '../assests/Cover Photos/Hustlers/wines and spirits.png';

import ChipsAndChickenImage from '../assests/Cover Photos/Hustlers/chips and chicken.png';
import DaycareImage from '../assests/Cover Photos/Hustlers/daycare.png';
import LaundryImage from '../assests/Cover Photos/Hustlers/laundry .png';
import MilkImage from '../assests/Cover Photos/Hustlers/milk.png';
import PetrolStationImage from '../assests/Cover Photos/Hustlers/petrol station.png';
import QuarryImage from '../assests/Cover Photos/Hustlers/Quarry.png';
import TentsAndChairImage from '../assests/Cover Photos/Hustlers/tents and chair.png';

import ConcretingBusinessImage from '../assests/Cover Photos/Hustlers/concreting business.png';
import ElectricalPartsImage from '../assests/Cover Photos/Hustlers/electrical parts.png';
import LeatherBusinessGuideImage from '../assests/Cover Photos/Hustlers/leather business guide.png';
import MitumbaClothesImage from '../assests/Cover Photos/Hustlers/mitumba clothes.png';
import PharmacyImage from '../assests/Cover Photos/Hustlers/Pharmacy.png';
import RealEstateImage from '../assests/Cover Photos/Hustlers/real estate.png';
import TimberRetailImage from '../assests/Cover Photos/Hustlers/timber retail.png';

import AutoGarageGuide from '../assests/ebook pdfs/HUSTLERS/Auto Garage Guide Hustler Playbook.pdf';
import MedicalImagingClinic from '../assests/ebook pdfs/HUSTLERS/Medical Imaging Clinic.pdf';
import BodaBodaBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Boda Boda business guide.pdf';
import MilkDistributionBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Milk Distribution Business Guide Hustlers Playbook.pdf';
import CarWashBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Car Wash Business Guide Kalasik.pdf';
import MpesaSubAgencyGuide from '../assests/ebook pdfs/HUSTLERS/M-pesa Sub Agency Guide.pdf';
import ChipsAndChickenCafeGuide from '../assests/ebook pdfs/HUSTLERS/Chips and Chicken Cafe Guide.docx - Google Docs.pdf';
import PharmacyGuide from '../assests/ebook pdfs/HUSTLERS/Pharmacy Guide Hustlers Playbook.pdf';
import ConcretingBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Concreting Business Guide.pdf';
import PlumbingRetail from '../assests/ebook pdfs/HUSTLERS/Plumbing Retail.pdf';
import CyberCafeGuide from '../assests/ebook pdfs/HUSTLERS/Cyber Cafe Hustlers Playbook.pdf';
import PrintingBrandingBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Printing _ Branding Business Guide.pdf';
import ElectricalPartsRetailQuickGuide from '../assests/ebook pdfs/HUSTLERS/Electrical Parts Retail Quick Guide.pdf';
import QuarryBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Quarry Business Guide Hustlers Playbook.pdf';
import ExecutiveBarberShop from '../assests/ebook pdfs/HUSTLERS/Executive Barber Shop.pdf';
import RealEstateAgencyKalasik from '../assests/ebook pdfs/HUSTLERS/Real Estate Agency Kalasik.pdf';
import IndependentPetrolStationKalasik from '../assests/ebook pdfs/HUSTLERS/Independent Petrol Station Kalasik.pdf';
import TimberRetailBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Timber Retail Business Guide.pdf';
import LaundryBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Laundry Business Guide.pdf';
import TyresRetailBusiness from '../assests/ebook pdfs/HUSTLERS/Tyres Retail Business.pdf';
import LeatherBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Leather Business Guide.pdf';
import WinesAndSpiritsBusinessGuide from '../assests/ebook pdfs/HUSTLERS/Wines and Spirits Business Guide Hustlers Playbook.pdf';


// Define sample data for the cards
const cardData = [];

// List of possible words to use in titles
const words = [
  'Business',
  'Startup',
  'Entrepreneurship',
  'Success',
  'Guide',
  'Strategy',
  'Marketing',
  'Finance',
  'Leadership',
  'Innovation',
  'Management',

  'Productivity',
  'Sales',
  'Growth',
  'Profit',
  'Market',
  'Ideas',
  'Investment',
  'Opportunity',
  'Economy',
  'Development',
  'Brand',
  'Advertising',
  'Digital',
  'Technology',
  'Online',
  'Social',
  'Networking',
];
const categories = [
  { value: 'all', label: 'All Books' },
  { value: 'Health and Well-being', label: 'Health and Well-being' },
  { value: 'Practical Skills and Maintenance', label: 'Practical Skills and Maintenance' },
  { value: 'Personal and Professional Development', label: 'Personal and Professional Development' },
  // ... add more categories as needed
];
for (let i = 1; i <= 100; i++)
{
  // Generate a random title by combining two random words
  const title = `${getRandomWord()} ${getRandomWord()}`;
  cardData.push({
    id: i,
    title,
    price: `$${Math.floor(Math.random() * 50) + 10}`, // Random price between $10 and $59
  });
}
const pdfData = [
  { id: 1, title: 'Auto Garage Guide', file: AutoGarageGuide, price: 'KES 100', image: GarageImage },
  { id: 2, title: 'Medical Imaging Clinic', file: MedicalImagingClinic, price: 'KES 100', image: MedicalImagingImage },
  { id: 3, title: 'Boda Boda Business Guide', file: BodaBodaBusinessGuide, price: 'KES 100', image: BodaBodaBusinessGuideImage },
  { id: 4, title: 'Milk Distribution Business Guide', file: MilkDistributionBusinessGuide, price: 'KES 100', image: MilkImage },
  { id: 5, title: 'Car Wash Business Guide', file: CarWashBusinessGuide, price: 'KES 100', image: CarWashImage },
  { id: 6, title: 'M-pesa Sub Agency Guide', file: MpesaSubAgencyGuide, price: 'KES 100', image: MpesaAgentImage },
  { id: 7, title: 'Chips and Chicken Cafe Guide', file: ChipsAndChickenCafeGuide, price: 'KES 100', image: ChipsAndChickenImage },
  { id: 8, title: 'Pharmacy Guide', file: PharmacyGuide, price: 'KES 100', image: PharmacyImage },
  { id: 9, title: 'Concreting Business Guide', file: ConcretingBusinessGuide, price: 'KES 100', image: ConcretingBusinessImage },
  { id: 10, title: 'Plumbing Retail', file: PlumbingRetail, price: 'KES 100', image: PlumbingHardwareImage },
  { id: 11, title: 'Cyber Cafe Hustlers Playbook', file: CyberCafeGuide, price: 'KES 100', image: CyberCafeImage },
  { id: 12, title: 'Printing & Branding Business Guide', file: PrintingBrandingBusinessGuide, price: 'KES 100', image: PrintingAndBrandingImage },
  { id: 13, title: 'Electrical Parts Retail Quick Guide', file: ElectricalPartsRetailQuickGuide, price: 'KES 100', image: ElectricalPartsImage },
  { id: 14, title: 'Quarry Business Guide', file: QuarryBusinessGuide, price: 'KES 100', image: QuarryImage },
  { id: 15, title: 'Executive Barber Shop', file: ExecutiveBarberShop, price: 'KES 100', image: ExecutiveBarberShopImage },
  { id: 16, title: 'Real Estate Agency Kalasik', file: RealEstateAgencyKalasik, price: 'KES 100', image: RealEstateImage },
  { id: 17, title: 'Independent Petrol Station Kalasik', file: IndependentPetrolStationKalasik, price: 'KES 100', image: PetrolStationImage },
  { id: 18, title: 'Timber Retail Business Guide', file: TimberRetailBusinessGuide, price: 'KES 100', image: TimberRetailImage },
  { id: 19, title: 'Laundry Business Guide', file: LaundryBusinessGuide, price: 'KES 100', image: LaundryImage },
  { id: 20, title: 'Tyres Retail Business', file: TyresRetailBusiness, price: 'KES 100', image: TyreRetailImage },
  { id: 21, title: 'Leather Business Guide', file: LeatherBusinessGuide, price: 'KES 100', image: LeatherBusinessGuideImage },
  { id: 22, title: 'Wines and Spirits Business Guide', file: WinesAndSpiritsBusinessGuide, price: 'KES 100', image: WinesAndSpiritsImage },
];


// Function to get a random word from the 'words' array
function getRandomWord() {
  const randomIndex = Math.floor(Math.random() * words.length);
  return words[randomIndex];
}

export default function HustlerPlaybookPage() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };
  // Function to handle search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter cards based on search query
  // const filteredCards = cardData.filter((card) => {
  //   if (selectedCategory === 'all')
  //   {
  //     return true; // Show all cards if "All Books" is selected
  //   } else
  //   {
  //     return card.category === selectedCategory; // Filter by category
  //   }
  // });
  const filteredPDFs = pdfData.filter((pdf) => {
    const lowerCaseTitle = pdf.title.toLowerCase();

    // If the search query is empty, display all items
    if (!searchQuery)
    {
      return true;
    }

    return lowerCaseTitle.includes(searchQuery.toLowerCase());
  });
  return (
    <div className='App2'>
      <div className='hConatiner'>
        <NavBar />
        <br />
        <Stack justifyContent={'center'} alignItems={'center'} flexDirection='column' sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
            Welcome to the Kenya Business Playbook
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
            Explore a collection of ebooks about starting and growing businesses in Kenya.
          </Typography>
          <div>
            <TextField
              type="text"
              label="Search Books"
              variant="filled"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ background: 'white', marginTop: 3, width: { xs: '80vw', md: '40vw' } }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </div>
        </Stack>

      </div>
      <Stack direction='row' paddinRight={10}>
        <Tabs sx={{ padding: 2, display: { xs: 'none', md: 'none' }, background: '#495492', color: 'white', borderRadius: 8, borderTopLeftRadius: 0, width: '20%' }} value={selectedCategory} onChange={handleCategoryChange} aria-label="book categories" orientation='vertical' textColor="secondary"
          indicatorColor="secondary">
          {categories.map((category) => (
            <div style={{ margin: 'auto', width: '100%' }}>
              <Tab key={category.value} label={category.label} sx={{
                width: '100%',
                '&:hover': {
                  backgroundColor: 'lightblue', // Your desired background color on hover
                  color: 'black',             // Your desired text color on hover
                },
              }} />
              <Divider></Divider>
            </div>
          ))}
        </Tabs>
        <Grid container spacing={2} className="card-container" sx={{ padding: 4 }}>
          {filteredPDFs.map((pdf) => (
            <Grid item xs={6} sm={6} md={2} key={pdf.id} >
              <Card alignItems='center' sx={{ display: 'flex', flexDirection: 'column-reverse', minHeight: '100%' }} >
                <CardActions alignItems='end' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }} >
                  <PaymentModal sx={{ margin: 'auto' }} ct={pdf.title} pdf={pdf.file}></PaymentModal>
                </CardActions >
                <CardContent sx={{ height: '5rem' }}>
                  <Typography gutterBottom variant="h6" component="div" fontSize={14} >
                    {pdf.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" fontSize={12}>
                    Price: {pdf.price}
                  </Typography>
                </CardContent>
                {/* 
        You can add the logic to open the PDF when the user clicks on the card.
        Example: Use a modal or redirect the user to a new page.
        */}

                <CardMedia
                  component="img"
                  alt={pdf.title}
                  height="200"
                  image={pdf.image} // Use the corresponding image variable
                />
              </Card>
            </Grid>
          ))
          }
        </Grid >
      </Stack >

    </div >
  );
};