import React, { useState, useEffect } from 'react';
import NavBarVerTwo from '../components/NavBarVerTwo';
import { useLocation } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
function PdfDisplay() {

    const { state } = useLocation();

    useEffect(() => {
        if (state && state.subOutline)
        {
            $('#iframeTou').attr('src', state.subOutline);
        }
    }, [state]);

    return (
        <div style={{ height: '100vh', marginTop: '30px' }}>
            <NavBarVerTwo gid={state?.gradeId} navFlag={state.navFlag} />
            {/* <iframe
                id="iframeTou"
                title="PDF Display"
                width="100%"
                height="100%"
                allow="fullscreen"
                src
            /> */}
            <object id="fgh" data={state?.subOutline} width="100%"
                height="100%" type="application/pdf"></object>

        </div >
    );
}


export default PdfDisplay;
