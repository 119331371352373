
// App.js
import React, { lazy, Suspense } from 'react';
import NavBarVerTwo from '../components/NavBarVerTwo';
import cbc_intergrated from "../assests/CBC_integrated_science.pdf"
import { Link, useLocation } from 'react-router-dom';

const LazyPDFViewer = lazy(() => import('../components/LazyPdfViewer'));
const PdfViewer = () => {
    const pdfUrl = 'your-pdf-file.pdf';
    let { state } = useLocation();
    return (
        <div style={{ height: '100vh', marginTop: '30px' }}>
            <NavBarVerTwo gid={state.gradeId} navFlag={state.navFlag} />
            <br></br>
            <iframe src={state.subBook} width="100%" height={'100%'} />
        </div>
    );
};


export default PdfViewer