import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Import Grid component from Material-UI
import { CardActions, Divider, InputAdornment, Stack, Tab, Tabs } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PaymentModal from './PaymentModal';
import '../App.css'
import AgricultureImage from '../assests/Cover Photos/Skills/batch 1/8.png';
import CommunicationSkillsImage from '../assests/Cover Photos/Skills/batch 1/9.png';

import BasicDIYHomeRepairsImage from '../assests/Cover Photos/Skills/batch 3/9.png';
import BasicFirstAidImage from '../assests/Cover Photos/Skills/batch 3/2.png';
import BasicHomeRepairsImage from '../assests/Cover Photos/Skills/batch 2/10.png';
import BasicSelfDefenseImage from '../assests/Cover Photos/Skills/batch 4/4.png';
import BasicSewingAndClothingCareImage from '../assests/Cover Photos/Skills/batch 3/7.png';
import CarMaintenanceAndRepairImage from '../assests/Cover Photos/Skills/batch 3/3.png';
import CareerAndProfessionalDevelopmentImage from '../assests/Cover Photos/Skills/batch 2/8.png';
import CharacterDevelopmentImage from '../assests/Cover Photos/Skills/batch 4/6.png';
import CivicAndSocialResponsibilitiesImage from '../assests/Cover Photos/Skills/batch 3/1.png';
import CookingAndNutritionImage from '../assests/Cover Photos/Skills/batch 2/9.png';
import DigitalLiteracyImage from '../assests/Cover Photos/Skills/batch 2/7.png';
import DrugUseImage from '../assests/Cover Photos/Skills/batch 1/3.png';
import EmotionalIntelligenceImage from '../assests/Cover Photos/Skills/batch 4/3.png';
import FinancialLiteracyImage from '../assests/Cover Photos/Skills/batch 1/6.png';
import HealthAndWellnessImage from '../assests/Cover Photos/Skills/batch 2/3.png';
import HomeManagementImage from '../assests/Cover Photos/Skills/batch 3/4.png';
import HowToBeASuccessfulPoliticianImage from '../assests/Cover Photos/Skills/batch 5/12.png';
import HowToDressProperlyImage from '../assests/Cover Photos/Skills/batch 4/8.png';
import InterpersonalSkillsImage from '../assests/Cover Photos/Skills/batch 2/5.png';
import JourneyFromBoyToManImage from '../assests/Cover Photos/Skills/batch 4/7.png';
import LegalRightsAndResponsibilitiesImage from '../assests/Cover Photos/Skills/batch 4/1.png';
import LifeSkillsImage from '../assests/Cover Photos/Skills/batch 1/5.png';
import MentalHealthEducationImage from '../assests/Cover Photos/Skills/batch 1/7.png';
import MotherhoodImage from '../assests/Cover Photos/Skills/batch 1/2.png';
import NegotiationAndConflictResolutionImage from '../assests/Cover Photos/Skills/batch 3/10.png';
import OnlinePrivacyAndSecurityImage from '../assests/Cover Photos/Skills/batch 4/2.png';
import PersonalEtiquetteAndMannersImage from '../assests/Cover Photos/Skills/batch 3/8.png';
import PersonalFinanceImage from '../assests/Cover Photos/Skills/batch 1/10.png';
import PetCareImage from '../assests/Cover Photos/Skills/batch 1/10.png';
import ProblemSolvingAndCriticalThinkingImage from '../assests/Cover Photos/Skills/batch 2/1.png';
import PublicSpeakingImage from '../assests/Cover Photos/Skills/publicSpeaking.png';
import RelationshipAndDatingSkillsImage from '../assests/Cover Photos/Skills/batch 3/6.png';
import SelfConfidenceAndSelfEsteemImage from '../assests/Cover Photos/Skills/batch 2/11.png';
import SocialMediaMarketingImage from '../assests/Cover Photos/Skills/batch 4/5.png';
import StressManagementImage from '../assests/Cover Photos/Skills/batch 2/2.png';
import TaxesImage from '../assests/Cover Photos/Cover Photos/Social Science/Social science grade 4.png';
import TimeManagementAndOrganizationImage from '../assests/Cover Photos/Skills/batch 1/11.png';
import TravelPlanningImage from '../assests/Cover Photos/Skills/batch 3/5.png';
import WomenHealthImage from '../assests/Cover Photos/Skills/batch 1/1.png';
import LeadershipImage from '../assests/Cover Photos/Skills/batch 5/11.png';


import AgricultureOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/AGRICULTURE - Sheet1.pdf';

import BasicDIYHomeRepairsOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/BASIC DIY HOME REPAIRS - Sheet1.pdf';

import BasicFirstAidOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/Basic First Aid and Emergency Response - Sheet1.pdf';

import BasicHomeRepairsOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/BASIC HOME REPAIRS - Sheet1.pdf';

import BasicSelfDefenseOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/BASIC SELF-DEFENSE - Sheet1.pdf';

import BasicSewingAndClothingCareOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/BASIC SEWING AND CLOTHING CARE - Sheet1.pdf';

import CarMaintenanceAndRepairOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/Car Maintenance and Repair - Sheet1.pdf';

import CareerAndProfessionalDevelopmentOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/Career and Professional Development - Sheet1.pdf';

import CharacterDevelopmentOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/CHARACTER DEVELOPMENT - Sheet1.pdf';

import CivicAndSocialResponsibilitiesOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/CIVIC AND SOCIAL RESPONSIBILITIES - Sheet1.pdf';

import CookingAndNutritionOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/COOKING AND NUTRITION - Sheet1.pdf';

import DigitalLiteracyOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/DIGITAL LITERACY - Sheet1.pdf';

import DrugUseOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/DRUG USE - Sheet1.pdf';

import EmotionalIntelligenceOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/EMOTIONAL INTELLIGENCE - Sheet1.pdf';

import FinancialLiteracyOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/FINANCIAL LITERACY - Sheet1.pdf';

import HealthAndWellnessOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/HEALTH AND WELLNESS - Sheet1.pdf';

import HomeManagementOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/HOME MANAGEMENT - Sheet1.pdf';

import HowToBeASuccessfulPoliticianOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/How to be a successful politician - Sheet1.pdf';

import HowToDressProperlyOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/HOW TO DRESS PROPERLY - Sheet1.pdf';

import InterpersonalSkillsOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/INTERPERSONAL SKILLS - Sheet1.pdf';

import JourneyFromBoyToManOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/JOURNEY FROM BOY TO MAN - Sheet1.pdf';

import LegalRightsAndResponsibilitiesOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/LEGAL RIGHTS AND RESPONSIBILITIES - Sheet1.pdf';

import LifeSkillsOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/LIFE SKILLS - Sheet1.pdf';

import MentalHealthEducationOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/MENTAL HEALTH EDUCATION - Sheet1.pdf';

import MotherhoodOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/MOTHERHOOD - Sheet1.pdf';

import NegotiationAndConflictResolutionOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/NEGOTIATION AND CONFLICT RESOLUTION - Sheet1.pdf';

import OnlinePrivacyAndSecurityOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/ONLINE PRIVACY AND SECURITY - Sheet1.pdf';

import PersonalEtiquetteAndMannersOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/PERSONAL ETIQUETTE AND MANNERS - Sheet1.pdf';

import PersonalFinanceOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/PERSONAL FINANCE - Sheet1.pdf';

import ProblemSolvingAndCriticalThinkingOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/PROBLEM SOLVING AND CRITICAL THINKING - Sheet1.pdf';

import PublicSpeakingOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/PUBLIC SPEAKING - Sheet1-1.pdf';

import RelationshipAndDatingSkillsOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/RELATIONSHIP AND DATING SKILLS - Sheet1.pdf';

import SelfConfidenceAndSelfEsteemOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/Self-Confidence and Self- Esteem - Sheet1.pdf';

import SocialMediaMarketingOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/SOCIAL MEDIA MARKETING - Sheet1.pdf';

import StressManagementOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/STRESS MANAGEMENT - Sheet1-1.pdf';

import TaxesOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/TAXES - Sheet1-1.pdf';

import TimeManagementAndOrganizationOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/TIME MANAGEMENT AND ORGANIZATION - Sheet1.pdf';

import TravelPlanningOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/TRAVEL PLANNING - Sheet1.pdf';

import WomenHealthOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/WOMEN_S HEALTH - Sheet1.pdf';
import PetCareOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/WOMEN_S HEALTH - Sheet1.pdf';
import CommunicationSkillsOutlinePDF
  from
  '../assests/ebook pdfs/SKILLS/Skills Outlines/COMMUNICATION SKILLS - Google Sheets.pdf';



import AgriculturePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/AGRICULTURE.pdf';
import CommunicationSkillsPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/COMMUNICATION SKILLS.pdf';
import DrugUsePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/DRUG USE.pdf';
import FinancialLiteracyPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/FINANCIAL LITERACY.pdf';
import LifeSkillsPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/LIFE SKILLS.pdf';
import MentalHealthEducationPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/MENTAL HEALTH EDUCATION.pdf';
import MotherhoodPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/MOTHERHOOD.pdf';
import PersonalFinancePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/PERSONAL FINANCE.pdf';
import TimeManagementAndOrganizationPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/TIME MANAGEMENT AND ORGANIZATION.pdf';
import WomensHealthPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/WOMENS HEALTH.pdf';

import BasicHomeRepairsPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/BASIC HOME REPAIRS.pdf';
import CareerAndProfessionalDevelopmentPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/Career and Professional Development.pdf';
import CookingAndNutritionPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/COOKING AND NUTRITION.pdf';
import CriticalThinkingAndProblemSolvingPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/CRITICAL THINKING AND PROBLEM SOLVING.pdf';
import DigitalLiteracyPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/DIGITAL LITERACY.pdf';
import HealthAndWellnessPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/HEALTH AND WELLNESS.pdf';
import StressManagementPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/STRESS MANAGEMENT.pdf';
import PublicSpeakingPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/PUBLIC SPEAKING.pdf';


import BasicDIYHomeRepairsPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/BASIC DIY HOME REPAIRS.pdf';
import BasicFirstAidAndEmergencyResponsePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/BASIC FIRST AID AND EMERGENCY RESPONSE.pdf';
import BasicSewingAndClothingCarePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/BASIC SEWING AND CLOTHING CARE.pdf';
import CarMaintenanceAndRepairPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/CAR MAINTENANCE AND REPAIR.pdf';
import CivicAndSocialResponsibilitiesPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/CIVIC AND SOCIAL RESPONSIBILITIES.pdf';
import HomeManagementPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/HOME MANAGEMENT.pdf';
import NegotiationAndConflictResolutionPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/NEGOTIATION AND CONFLICT RESOLUTION.pdf';
import PersonalEtiquetteAndMannersPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/PERSONAL ETIQUETTE AND MANNERS.pdf';
import RelationshipAndDatingSkillsPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/RELATIONSHIP AND DATING SKILLS.pdf';
import TravelPlanningPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 3/TRAVEL PLANNING.pdf';



import BasicSelfDefensePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/BASIC SELF-DEFENSE.pdf';
import CharacterDevelopmentPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/CHARACTER DEVELOPMENT.pdf';
import EmotionalIntelligencePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/EMOTIONAL INTELLIGENCE.pdf';
import GlobalAffairsPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/GLOBAL AFFAIRS.pdf';
import HistoryOfKenyaPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/HISTORY OF KENYA.pdf';
import HowToDressProperlyPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/How to Dress Properly.pdf';
import JourneyFromBoyToManPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/JOURNEY FROM BOY TO MAN.pdf';
import LegalRightsAndResponsibilitiesPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/LEGAL RIGHTS AND RESPONSIBILITIES.pdf';
import OnlinePrivacyAndSecurityPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/ONLINE PRIVACY AND SECURITY.pdf';
import SocialMediaMarketingPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/SOCIAL MEDIA MARKETING.pdf';


import BasicHomeGardeningPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/Basic home Gardening.pdf';
import CreativityAndInnovationPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/CREATIVITY AND INNOVATION.pdf';
import EntrepreneurshipPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/ENTREPRENEURSHIP.pdf';
import EnvironmentalEducationPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/ENVIRONMENTAL EDUCATION.pdf';
import HowToBeASuccessfulPoliticianPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/HOW TO BE A SUCCESSFUL POLITICIAN.pdf';
import HowToBeStreetSmartPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/HOW TO BE STREET SMART.pdf';
import HowToManageMyCarAndRoadSafetyPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/HOW TO MANAGE MY CAR AND ROAD SAFETY.pdf';
import HowToPrepareForAnExamPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/HOW TO PREPARE FOR AN EXAM.pdf';
import HowToTakeCareOfOurHomesPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/HOW TO TAKE CARE OF OUR HOMES.pdf';
import LeadershipPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/LEADERSHIP.pdf';
import LivestockCarePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/LIVESTOCK CARE.pdf';
import MostEfficientWayToSourceWaterPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/Most efficient way to source water.pdf';
import PetCarePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 5/PET CARE.pdf';
import InterpersonalSkillsPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/INTERPERSONAL SKILLS.pdf';
import SelfConfidenceAndSelfEsteemPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/SELF CONFIDENCE AND SELF-ESTEEM.pdf';
import { Link } from 'react-router-dom';




// Define sample data for the cards

const categories = [
  { value: 'all', label: 'All Books' },
  { value: 'Health and Well-being', label: 'Health and Well-being' },
  { value: 'Practical Skills and Maintenance', label: 'Practical Skills and Maintenance' },
  { value: 'Personal and Professional Development', label: 'Personal and Professional Development' },

  // ... add more categories as needed
];
// for (let i = 1; i <= 100; i++)
// {
//   // Generate a random title by combining two random words
//   const title = `${getRandomWord()} ${getRandomWord()}`;
//   cardData.push({
//     id: i,
//     title,
//     price: `$${Math.floor(Math.random() * 50) + 10}`, // Random price between $10 and $59
//   });
// }

const skillsData = [
  // Health and Well-being
  { id: 1, title: "Women's Health", pdfBookLink: WomensHealthPDF, pdfOutline: WomenHealthOutlinePDF, img: WomenHealthImage, category: 'Health and Well-being' },
  { id: 2, title: 'Mental Health Education', pdfBookLink: MentalHealthEducationPDF, pdfOutline: MentalHealthEducationOutlinePDF, img: MentalHealthEducationImage, category: 'Health and Well-being' },
  { id: 3, title: 'Drug Use', pdfBookLink: DrugUsePDF, pdfOutline: DrugUseOutlinePDF, img: DrugUseImage, category: 'Health and Well-being' },
  { id: 4, title: 'Stress Management', pdfBookLink: StressManagementPDF, pdfOutline: StressManagementOutlinePDF, img: StressManagementImage, category: 'Health and Well-being' },
  { id: 5, title: 'Health and Wellness', pdfBookLink: HealthAndWellnessPDF, pdfOutline: HealthAndWellnessOutlinePDF, img: HealthAndWellnessImage, category: 'Health and Well-being' },
  { id: 6, title: 'Basic First Aid and Emergency Response', pdfBookLink: BasicFirstAidAndEmergencyResponsePDF, pdfOutline: BasicFirstAidOutlinePDF, img: BasicFirstAidImage, category: 'Health and Well-being' },
  { id: 7, title: 'Self-Confidence and Self-Esteem', pdfBookLink: SelfConfidenceAndSelfEsteemPDF, pdfOutline: SelfConfidenceAndSelfEsteemOutlinePDF, img: SelfConfidenceAndSelfEsteemImage, category: 'Health and Well-being' },
  //{ id: 8, title: 'PET CARE', pdfBookLink: PetCarePDF, pdfOutline: PetCareOutlinePDF, img: PetCareImage, category: 'Health and Well-being' },
  //{ id: 9, title: 'LIVESTOCK CARE', pdfBookLink: LivestockCarePDF, pdfOutline: LivestockCareOutlinePDF, img: LivestockCareImage, category: 'Health and Well-being' },
  { id: 10, title: "MotherHood", pdfBookLink: MotherhoodPDF, pdfOutline: MotherhoodOutlinePDF, img: MotherhoodImage, category: 'Health and Well-being' },

  // Practical Skills and Maintenance
  { id: 10, title: 'Life Skills', pdfBookLink: LifeSkillsPDF, pdfOutline: LifeSkillsOutlinePDF, img: LifeSkillsImage, category: 'Practical Skills and Maintenance' },
  { id: 11, title: 'Agriculture', pdfBookLink: AgriculturePDF, pdfOutline: AgricultureOutlinePDF, img: AgricultureImage, category: 'Practical Skills and Maintenance' },
  { id: 12, title: 'Basic Home Repairs', pdfBookLink: BasicHomeRepairsPDF, pdfOutline: BasicHomeRepairsOutlinePDF, img: BasicHomeRepairsImage, category: 'Practical Skills and Maintenance' },
  { id: 13, title: 'Car Maintenance and Repair', pdfBookLink: CarMaintenanceAndRepairPDF, pdfOutline: CarMaintenanceAndRepairOutlinePDF, img: CarMaintenanceAndRepairImage, category: 'Practical Skills and Maintenance' },
  { id: 14, title: 'Home Management', pdfBookLink: HomeManagementPDF, pdfOutline: HomeManagementOutlinePDF, img: HomeManagementImage, category: 'Practical Skills and Maintenance' },
  { id: 15, title: 'Travel Planning', pdfBookLink: TravelPlanningPDF, pdfOutline: TravelPlanningOutlinePDF, img: TravelPlanningImage, category: 'Practical Skills and Maintenance' },
  { id: 16, title: 'Basic Sewing and Clothing Care', pdfBookLink: BasicSewingAndClothingCarePDF, pdfOutline: BasicSewingAndClothingCareOutlinePDF, img: BasicSewingAndClothingCareImage, category: 'Practical Skills and Maintenance' },
  { id: 17, title: 'Basic DIY Home Repairs', pdfBookLink: BasicDIYHomeRepairsPDF, pdfOutline: BasicDIYHomeRepairsOutlinePDF, img: BasicDIYHomeRepairsImage, category: 'Practical Skills and Maintenance' },
  //{ id: 18, title: 'Car and Road Safety', pdfBookLink: CarAndRoadSafetyPDF, pdfOutline: CarAndRoadSafetyOutlinePDF, img: CarAndRoadSafetyImage, category: 'Practical Skills and Maintenance' },
  //{ id: 19, title: 'How to take care of our Homes', pdfBookLink: HomeCarePDF, pdfOutline: HomeCareOutlinePDF, img: HomeCareImage, category: 'Practical Skills and Maintenance' },
  //{ id: 20, title: 'Most efficient way to source water', pdfBookLink: EfficientWaterSourcingPDF, pdfOutline: EfficientWaterSourcingOutlinePDF, img: EfficientWaterSourcingImage, category: 'Practical Skills and Maintenance' },
  //{ id: 21, title: 'Basic Home Gardening', pdfBookLink: BasicHomeGardeningPDF, pdfOutline: BasicHomeGardeningOutlinePDF, img: BasicHomeGardeningImage, category: 'Practical Skills and Maintenance' },

  // Personal and Professional Development
  { id: 59, title: 'Financial Literacy', pdfBookLink: FinancialLiteracyPDF, pdfOutline: FinancialLiteracyOutlinePDF, img: FinancialLiteracyImage, category: 'Personal and Professional Development' },
  { id: 60, title: 'Communication Skills', pdfBookLink: CommunicationSkillsPDF, pdfOutline: CommunicationSkillsOutlinePDF, img: CommunicationSkillsImage, category: 'Personal and Professional Development' },
  { id: 61, title: 'Personal Finance', pdfBookLink: PersonalFinancePDF, pdfOutline: PersonalFinanceOutlinePDF, img: PersonalFinanceImage, category: 'Personal and Professional Development' },
  { id: 62, title: 'Time Management and Organization', pdfBookLink: TimeManagementAndOrganizationPDF, pdfOutline: TimeManagementAndOrganizationOutlinePDF, img: TimeManagementAndOrganizationImage, category: 'Personal and Professional Development' },
  { id: 63, title: 'Problem Solving and Critical Thinking', pdfBookLink: CriticalThinkingAndProblemSolvingPDF, pdfOutline: ProblemSolvingAndCriticalThinkingOutlinePDF, img: ProblemSolvingAndCriticalThinkingImage, category: 'Personal and Professional Development' },
  //{ id: 64, title: 'Interpersonal Skills', pdfBookLink: InterpersonalSkillsPDF, pdfOutline: InterpersonalSkillsOutlinePDF, img: InterpersonalSkillsImage, category: 'Personal and Professional Development' },
  { id: 65, title: 'Digital Literacy', pdfBookLink: DigitalLiteracyPDF, pdfOutline: DigitalLiteracyOutlinePDF, img: DigitalLiteracyImage, category: 'Personal and Professional Development' },
  { id: 66, title: 'Career and Professional Development', pdfBookLink: CareerAndProfessionalDevelopmentPDF, pdfOutline: CareerAndProfessionalDevelopmentOutlinePDF, img: CareerAndProfessionalDevelopmentImage, category: 'Personal and Professional Development' },
  { id: 67, title: 'Relationship and Dating Skills', pdfBookLink: RelationshipAndDatingSkillsPDF, pdfOutline: RelationshipAndDatingSkillsOutlinePDF, img: RelationshipAndDatingSkillsImage, category: 'Personal and Professional Development' },
  { id: 68, title: 'Negotiation and Conflict Resolution', pdfBookLink: NegotiationAndConflictResolutionPDF, pdfOutline: NegotiationAndConflictResolutionOutlinePDF, img: NegotiationAndConflictResolutionImage, category: 'Personal and Professional Development' },
  { id: 69, title: 'Emotional Intelligence', pdfBookLink: EmotionalIntelligencePDF, pdfOutline: EmotionalIntelligenceOutlinePDF, img: EmotionalIntelligenceImage, category: 'Personal and Professional Development' },
  { id: 70, title: 'Social Media Marketing', pdfBookLink: SocialMediaMarketingPDF, pdfOutline: SocialMediaMarketingOutlinePDF, img: SocialMediaMarketingImage, category: 'Personal and Professional Development' },
  { id: 71, title: 'Character Development', pdfBookLink: CharacterDevelopmentPDF, pdfOutline: CharacterDevelopmentOutlinePDF, img: CharacterDevelopmentImage, category: 'Personal and Professional Development' },
  { id: 72, title: 'Journey from boy to Man', pdfBookLink: JourneyFromBoyToManPDF, pdfOutline: JourneyFromBoyToManOutlinePDF, img: JourneyFromBoyToManImage, category: 'Personal and Professional Development' },
  //{ id: 73, title: 'Leadership', pdfBookLink: LeadershipPDF, pdfOutline: LeadershipOutlinePDF, img: LeadershipImage, category: 'Personal and Professional Development' },
  //{ id: 74, title: 'How to be a successful politician', pdfBookLink: SuccessfulPoliticianPDF, pdfOutline: SuccessfulPoliticianOutlinePDF, img: SuccessfulPoliticianImage, category: 'Personal and Professional Development' },
  //{ id: 101, title: 'Taxes', pdfBookLink: SuccessfulPoliticianPDF, pdfOutline: TaxesOutlinePDF, img: TaxesImage, category: 'Personal and Professional Development' },
  { id: 110, title: 'Public Speaking', pdfOutline: PublicSpeakingOutlinePDF, pdfBookLink: PublicSpeakingPDF, img: PublicSpeakingImage, category: 'Personal and Professional Development' },
  // Cultural and Global Understanding
  //{ id: 75, title: 'History of Kenya', pdfBookLink: HistoryOfKenyaPDF, pdfOutline: HistoryOfKenyaOutlinePDF, img: HistoryOfKenyaImage, category: 'Cultural and Global Understanding' },
  //{ id: 76, title: 'Global Affairs', pdfBookLink: GlobalAffairsPDF, pdfOutline: GlobalAffairsOutlinePDF, img: GlobalAffairsImage, category: 'Cultural and Global Understanding' },
  //{ id: 77, title: 'Environmental Education', pdfBookLink: EnvironmentalEducationPDF, pdfOutline: EnvironmentalEducationOutlinePDF, img: EnvironmentalEducationImage, category: 'Cultural and Global Understanding' },


];
// Function to get a random word from the 'words' array
// function getRandomWord() {
//   const randomIndex = Math.floor(Math.random() * words.length);
//   return words[randomIndex];
// }

export default function Skills() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };
  // Function to handle search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter cards based on search query
  // const filteredCards = cardData.filter((card) => {
  //   if (selectedCategory === 'all')
  //   {
  //     return true; // Show all cards if "All Books" is selected
  //   } else
  //   {
  //     return card.category === selectedCategory; // Filter by category
  //   }
  // });
  const filteredPDFs = skillsData.filter((pdf) => {
    const lowerCaseTitle = pdf.title.toLowerCase();

    // If the search query is empty, display all items
    if (!searchQuery)
    {
      return true;
    }

    return lowerCaseTitle.includes(searchQuery.toLowerCase());
  });
  return (
    <div className='App2'>
      <div className='hConatiner'>
        <NavBar />
        <br />
        <Stack justifyContent={'center'} alignItems={'center'} flexDirection='column' sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
            Welcome to the Our SKILLS Database
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
            Explore a collection of ebooks about growing your mind and body.
          </Typography>
          <div>
            <TextField
              type="text"
              label="Search Books"
              variant="filled"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ background: 'white', marginTop: 3, width: { xs: '80vw', md: '40vw' } }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </div>
        </Stack>

      </div>
      <Stack direction='row' paddinRight={10}>
        <Tabs textColor="secondary"
          indicatorColor="secondary" sx={{ padding: 2, background: '#495492', color: 'white', borderRadius: 8, borderTopLeftRadius: 0, width: '20%', display: { xs: 'none', md: 'none' } }} value={selectedCategory} onChange={handleCategoryChange} aria-label="book categories" orientation='vertical' >
          {categories.map((category) => (
            // <div style={{ margin: 'auto', width: '100%' }}>
            <Tab key={category.value} label={category.label} sx={{
              color: 'white',
              width: '100%',
              '&:hover': {
                backgroundColor: 'lightblue', // Your desired background color on hover
                color: 'black',             // Your desired text color on hover
              },
            }} />
            //   <Divider></Divider>
            // </div>
          ))}
        </Tabs>
        <Grid container spacing={2} className="card-container" sx={{ padding: 4 }}>
          {filteredPDFs.map((pdf) => (
            <Grid item xs={6} sm={6} md={2} key={pdf.id}>
              <Link to='/CoursePage' state={{ subjectName: pdf.title, subCover: pdf.img, subOutline: pdf.pdfOutline, subBook: pdf.pdfBookLink, navFlag: 1 }} style={{ textDecoration: 'none', color: 'black', fontSize: 12 }}>

                <Card alignItems='center' sx={{ display: 'flex', flexDirection: 'column-reverse', minHeight: '100%' }} >
                  <CardContent sx={{ height: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography gutterBottom variant="h6" component="div" fontSize={14} >
                      {pdf.title}
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                    Price: {pdf.price}
                  </Typography> */}
                  </CardContent>
                  <CardMedia
                    component="img"
                    alt={pdf.title}
                    height="200"
                    image={pdf.img} // Use the corresponding image variable
                  />

                  {/* 
        You can add the logic to open the PDF when the user clicks on the card.
        Example: Use a modal or redirect the user to a new page.
        */}
                  {/* <CardActions marginLeft='auto'> */}
                  {/* <Button onClick={() => window.open(pdf.file, '_blank')}>
                      View PDF
                    </Button>
                  </CardActions> */}
                </Card>
              </Link>

            </Grid>
          ))}
        </Grid>
      </Stack>

    </div >
  );
};