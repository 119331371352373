import React from 'react'
import { Box, Button, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import iconOne from '../assests/iconOne.png'
import iconTwo from '../assests/iconTwo.png'
import iconThree from '../assests/social-media.png'
import iconFour from '../assests/iconFour.png'
import iconFive from '../assests/iconFive.png'
import iconSix from '../assests/iconSix.png'
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import AgriculturePDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/AGRICULTURE.pdf';
import AgricultureOutlinePDF
    from
    '../assests/ebook pdfs/SKILLS/Skills Outlines/AGRICULTURE - Sheet1.pdf';
import AgricultureImage from '../assests/Cover Photos/Skills/batch 1/8.png';
import FinancialLiteracyImage from '../assests/Cover Photos/Skills/batch 1/6.png';
import FinancialLiteracyOutlinePDF
    from
    '../assests/ebook pdfs/SKILLS/Skills Outlines/FINANCIAL LITERACY - Sheet1.pdf';
import FinancialLiteracyPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/FINANCIAL LITERACY.pdf';

import WomensHealthPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 1/WOMENS HEALTH.pdf';
import SocialMediaMarketingPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 4/SOCIAL MEDIA MARKETING.pdf';
import CookingAndNutritionPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/COOKING AND NUTRITION.pdf';
import SocialMediaMarketingImage from '../assests/Cover Photos/Skills/batch 4/5.png';
import WomenHealthImage from '../assests/Cover Photos/Skills/batch 1/1.png';
import CookingAndNutritionImage from '../assests/Cover Photos/Skills/batch 2/9.png';
import PublicSpeakingPDF from '../assests/ebook pdfs/SKILLS/Books/Batch 2/PUBLIC SPEAKING.pdf';

import PublicSpeakingOutlinePDF
    from
    '../assests/ebook pdfs/SKILLS/Skills Outlines/PUBLIC SPEAKING - Sheet1-1.pdf';
import WomenHealthOutlinePDF
    from
    '../assests/ebook pdfs/SKILLS/Skills Outlines/WOMEN_S HEALTH - Sheet1.pdf';

import SocialMediaMarketingOutlinePDF
    from
    '../assests/ebook pdfs/SKILLS/Skills Outlines/SOCIAL MEDIA MARKETING - Sheet1.pdf';
import CookingAndNutritionOutlinePDF
    from
    '../assests/ebook pdfs/SKILLS/Skills Outlines/COOKING AND NUTRITION - Sheet1.pdf';

import PublicSpeakingImage from '../assests/Cover Photos/Skills/publicSpeaking.png';

const gradeList = [
    { id: 0, title: 'Financial Management', icon: iconSix, pdfBookLink: FinancialLiteracyPDF, pdfOutline: FinancialLiteracyOutlinePDF, img: FinancialLiteracyImage },
    { id: 2, title: 'Agriculture', icon: iconOne, pdfBookLink: AgriculturePDF, pdfOutline: AgricultureOutlinePDF, img: AgricultureImage },
    { id: 3, title: 'Women\'s Health', icon: iconTwo, pdfBookLink: WomensHealthPDF, pdfOutline: WomenHealthOutlinePDF, img: WomenHealthImage },
    { id: 4, title: 'Social Media', icon: iconThree, pdfBookLink: SocialMediaMarketingPDF, pdfOutline: SocialMediaMarketingOutlinePDF, img: SocialMediaMarketingImage },
    { id: 5, title: 'Cooking', icon: iconFour, pdfBookLink: CookingAndNutritionPDF, pdfOutline: CookingAndNutritionOutlinePDF, img: CookingAndNutritionImage },
    { id: 7, title: 'Public Speaking', icon: iconFive, pdfOutline: PublicSpeakingOutlinePDF, pdfBookLink: PublicSpeakingPDF, img: PublicSpeakingImage },

];

export default function SkillDatabase() {
    const navigate = useNavigate();

    return (
        <Paper sx={{ background: '#495492', borderRadius: '20px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} onClick={() => { navigate('/skills') }}>
            <Stack>
                <Box>
                    <Typography sx={{ color: 'white', fontSize: { xs: 20, md: 35 }, fontWeight: 'bold', fontFamily: 'Oswald', padding: 1 }}>
                        SKILL'S DATABASE
                    </Typography>
                </Box>
                <Box>
                    <Stack flexDirection='column' justifyContent={'center'} alignItems={'center'} >
                        <Typography sx={{ color: 'white', fontSize: { xs: 20, md: 35 }, textAlign: 'center' }}>
                            Take A Course
                        </Typography>
                        <Grid container onClick={(e) => e.stopPropagation()} sx={{ margin: 'auto' }}>
                            {gradeList.map((item, index) => (
                                <Grid item xs={4}>
                                    <Button
                                        key={index}
                                        sx={{ width: { xs: '100%', md: '33.3%' }, color: 'white', marginBottom: '10px', padding: '10px' }}
                                        onClick={() => { navigate('/CoursePage', { state: { subjectName: item.title, subCover: item.img, subOutline: item.pdfOutline, subBook: item.pdfBookLink } }) }}
                                    >
                                        <Stack justifyContent={'center'} alignItems={'center'}>
                                            <img src={item.icon} style={{ width: '60px' }} alt={item.title} />
                                            {item.title}
                                        </Stack>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Box>
            </Stack>

            <IconButton sx={{ backgroundColor: '#fec107', width: '50px', height: '50px', alignSelf: 'center', marginBottom: '10px' }}>
                <KeyboardDoubleArrowDownIcon sx={{ color: 'white' }} />
            </IconButton>
        </Paper>

    )
}
