import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import husMenuImg from '../assests/husMenuImg.png';
import { Link, useNavigate } from 'react-router-dom';

export default function HustlerMenuItem() {
    const navigate = useNavigate();

    return (
        <Paper
            sx={{ backgroundColor: 'white', borderRadius: '20px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backdropFilter: 'blur(26px)' }}
            onClick={() => { navigate('/hustlerPlaybook') }}
        >
            <Stack justifyContent={'center'} alignItems={'center'} >
                <Box>
                    <Typography sx={{ color: '#495492', fontSize: { xs: 20, md: 35 }, fontWeight: 'bold', fontFamily: 'Oswald', padding: 1 }}>
                        HUSTLER'S PLAYBOOK
                    </Typography>
                </Box>
                <Box>
                    <Stack flexDirection='column' alignItems={'center'}>
                        <img src={husMenuImg} style={{ width: '60%' }} alt="Book Cover" />
                        <Typography sx={{ color: '#495492', fontSize: { xs: 16, md: 18 }, width: '100%', textAlign: 'center' }}>
                            Discover the secrets of success with
                            'Hustler's Playbooks' - a unique
                            collection of business guides tailored
                            to help you thrive in Kenya's various
                            industries. Get ready to supercharge
                            your entrepreneurial journey
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
            <Link to='/hustlerPlaybook' style={{ alignSelf: 'center' }}>
                <IconButton sx={{ backgroundColor: '#495492', width: '50px', height: '50px', alignSelf: 'center', marginBottom: '10px' }}>
                    <KeyboardDoubleArrowDownIcon sx={{ color: 'white' }} />
                </IconButton>
            </Link>
        </Paper>
    );
}
