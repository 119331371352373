import React from 'react';
import { Grid, Typography, Container, Stack, Box, Paper, Divider } from '@mui/material';
import BookTile from '../assests/bookTile.png'; // Corrected the path to your image
import bgCourse from '../assests/bgCourse.png'; // Corrected the path to your image
import NavBarVerTwo from '../components/NavBarVerTwo';
import pdfIcon_bg_rm from '../assests/pdfIcon_bg_rm.png'
import downloadIcon_bg_rm from '../assests/downloadIcon_bg_rm.png'
import downbtn_bg_rm from '../assests/downbtn_bg_rm.png'
import book_icon from '../assests/book_icon.png'
import book_icon_two from '../assests/book_icon_two.png'
import coming_soon_banner from '../assests/coming_soon.png'
import { Link, useLocation } from 'react-router-dom';
export default function CoursePage() {
    let { state } = useLocation();
    console.log(state)
    return (
        <Box sx={{ backgroundColor: '#effafe', height: '100vh', width: '100vw' }}>
            <NavBarVerTwo gid={state.gradeId} navFlag={state.navFlag} />
            <br></br>
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ width: '100vw' }}>

                <Grid container justifyContent={'center'} spacing={1}>
                    <Grid item xs={12} sx={{
                        backgroundColor: 'white', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px', marginTop: 2, padding: 2, width: '70%',
                    }}>
                        <Typography
                            sx={{
                                color: '#242b48',
                                fontSize: { xs: 20, md: 35 },
                                fontWeight: 'bold',
                                fontFamily: 'Oswald',
                                marginLeft: 'auto',

                                marginRight: 'auto'
                            }}
                        >

                            {state.subjectName}
                        </Typography>
                    </Grid>
                    <Grid item xs={11} md={4} >
                        {/* <Paper style={{ backgroundColor: '#f6e8db', width: '95%', margin: 'auto' }}>
                            <img src={pdfIcon_bg_rm} style={{ width: '70%' }}>

                            </img>
                        </Paper> */}
                        <Link to="/pdf-display" style={{ textDecoration: 'none' }} state={{ subjectName: state.subName, subjectIcon: state.subIcon, gradeId: state.gradeId, subOutline: state.subOutline, navFlag: state.navFlag }}>
                            <Paper style={{ backgroundColor: '#f6e8db', width: { xs: '70vw', md: '30vw' }, margin: 'auto', textAlign: 'center', borderRadius: '30px', padding: 1, height: '100%' }}>
                                <img src={pdfIcon_bg_rm} style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }} alt="Course Outline" />
                                <h2>Course Outline</h2>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item xs={11} md={4} >
                        <Link to="/pdf-display-ebook" style={{ textDecoration: 'none' }} state={{ subjectName: state.subName, subjectIcon: state.subIcon, gradeId: state.gradeId, subBook: state.subBook, navFlag: state.navFlag }}>
                            <Paper style={{ backgroundColor: '#eac3f8', width: { xs: '70vw', md: '30vw' }, margin: 'auto', textAlign: 'center', borderRadius: '30px', padding: 1, height: '100%' }}>
                                <img src={state.subCover ? state.subCover : coming_soon_banner} style={{ width: state.subCover ? '200px' : '300px', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }} alt="Course Outline" />
                                <h2>Kalasik Ebook</h2>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item xs={11} md={4}>
                        <Paper style={{ backgroundColor: '#f6e8db', width: { xs: '70vw', md: '30vw' }, margin: 'auto', textAlign: 'center', borderRadius: '30px', padding: 1, height: '100%' }}>
                            <Stack spacing={4}>
                                {/* <Typography style={{ marginTop: '10px' }}>
                                    Download
                                </Typography> */}
                                <img src={downbtn_bg_rm} style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }} alt="Course Outline" />
                                <a href={state.subBook} download style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Stack flexDirection='row' alignItems='center' justifyContent='space-evenly'>
                                        <img src={downloadIcon_bg_rm} style={{ width: '80px' }} alt="Course Outline" />
                                        <h2>Kalasik Ebook</h2>
                                    </Stack>
                                </a>
                                <Divider />
                                <a href={state.subOutline} download style={{ textDecoration: 'none', color: 'inherit' }}>

                                    <Stack flexDirection='row' alignItems='center' justifyContent='space-evenly'>
                                        <img src={downloadIcon_bg_rm} style={{ width: '80px' }} alt="Course Outline" />
                                        <h2>Course Outline</h2>
                                    </Stack>
                                </a>

                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Stack>
        </Box >
    );
}
