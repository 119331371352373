import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HustlerPlaybookPage from './pages/HustlerPlaybookPageV2';
import AboutUsPage from './pages/AboutUsPage';
import SolarSchool from './pages/SolarSchool';
import CoursePage from './pages/CoursePage';
import ContactUsPage from './pages/ContactUsPage';
import CbcMainPage from './pages/CbcMainPage';
import MemberValidationComponent from './pages/MemberValidationComponent';
import PdfDisplay from './pages/PdfDisplay';
import PdfDisplayEbook from './pages/PdfDisplayEbook';
import Skills from './pages/Skills';
import AiTeacher from './pages/AiTeacher';
import Sitemap from './Sitemap';

import AiLobby from './pages/AiLobby';
import SingleAIPage from './pages/SingleAIPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: '/hustlerPlaybook',
    element: <HustlerPlaybookPage />
  },
  {
    path: '/aboutUs',
    element: <AboutUsPage />
  },
  {
    path: '/solarSchool',
    element: <SolarSchool />
  },
  {
    path: '/CoursePage',
    element: <CoursePage />
  }
  ,
  {
    path: '/CbcMainPage',
    element: <CbcMainPage />
  },
  {
    path: '/MemberValidationComponent',
    element: <MemberValidationComponent />
  },
  {
    path: '/pdf-display',
    element: <PdfDisplay />
  },
  {
    path: '/pdf-display-ebook',
    element: <PdfDisplayEbook />
  },
  {
    path: '/skills',
    element: <Skills />
  },
  {
    path: '/aiTeacher',
    element: <AiTeacher />
  }
  ,
  {
    path: '/sitemap',
    element: <Sitemap />
  },
  {
    path: '/aiLobby',
    element: <AiLobby />
  },
  {
    path: '/aiSinglePage',
    element: <SingleAIPage />
  },
]);
root.render(
  <React.StrictMode>

    <RouterProvider router={router} />
    <script type='module' src='https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js'></script>
    <zapier-interfaces-chatbot-embed is-popup='true' chatbot-id='clrnsepky000v4mcbnq01q0df' height='500px' width='400px'></zapier-interfaces-chatbot-embed>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
