import React, { useEffect, useState } from 'react';

import './App.css';
import fileIcon from './assests/fileIcon.png'
import NavBar from './components/NavBar';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import CBCMenuItem from './components/CBCMenuItem';
import HustlerMenuItem from './components/HustlerMenuItem';
import SkillDatabase from './components/SkillDatabase';
import iconOne from './assests/iconOne.png'
import iconTwo from './assests/iconTwo.png'
import iconThree from './assests/iconThree.png'
import iconFour from './assests/iconFour.png'
import iconFive from './assests/iconFive.png'
import iconSix from './assests/iconSix.png'
import ContactUsPage from './pages/ContactUsPage';
import SolarSchool from './pages/SolarSchool';
import SubjectModal from './pages/SubjectModal';
import PaymentModal from './pages/PaymentModal';
import Bars from 'react-spinner';
import { Helmet } from 'react-helmet';
import { RingLoader } from 'react-spinners';


const skillslist = [
  { id: 0, title: 'Financial Management', icon: iconSix },
  { id: 1, title: 'Agriculture', icon: iconOne },
  { id: 2, title: 'Womens Health', icon: iconTwo },
  { id: 3, title: 'Taxes', icon: iconThree },
  { id: 4, title: 'Cooking', icon: iconFour },
  { id: 5, title: 'Public Speaking', icon: iconFive },
  { id: 6, title: 'Drug Use', icon: iconFive },
  { id: 7, title: 'Life Skills', icon: iconFive },
  { id: 8, title: 'Drug Use', icon: iconFive },
  { id: 9, title: 'Taxes', icon: iconFive },
  { id: 10, title: 'Communication Skills', icon: iconFive },
  { id: 11, title: 'Personal fees', icon: iconFive },

];
const gradeList = [
  'Grade 1',
  'Grade 2',
  'Grade 3',
  'Grade 4',
  'Grade 5',
  'Grade 6',
  'Grade 7',
  'Grade 8',
]

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      // Import and load all your assets
     
      import('./assests/fileIcon.png'),
      import('./assests/iconOne.png'),
      import('./assests/iconTwo.png'),
      import('./assests/iconThree.png'),
      import('./assests/iconFour.png'),
      import('./assests/iconFive.png'),
      import('./assests/iconSix.png'),

      import('./assests/OneIcon.png'),
      import('./assests/TwoIcon.png'),
      import('./assests/ThreeIcon.png'),
      import('./assests/FourIcon.png'),
      import('./assests/FiveIcon.png'),
      import('./assests/SixIcon.png'),
      import('./assests/SevenIcon.png'),
      import('./assests/EightIcon.png'),
      import('./assests/NineIcon.png'),
      import('./components/CBCMenuItem'),

      import("./assests/rulerBG.png"),
      import("./assests/agriIcon.png"),
      import("./assests/arabic.png"),
      import("./assests/artIcon.png"),
      import("./assests/eng.png"),
      import("./assests/french.png"),
      import("./assests/german.png"),
      import("./assests/bible.png"),
      import("./assests/comSciIcon.png"),
      import("./assests/bsICon.png"),
      import("./assests/chin.png"),
      import("./assests/healthEdu.png"),
      import("./assests/hindu.png"),
      import("./assests/homeScience.png"),
      import("./assests/life-skills.png"),
      import("./assests/quran.png"),
      import("./assests/sign-language.png"),
      import("./assests/swahili.png"),
      import("./assests/indegenous.png"),
      import("./assests/math.png"),
      import("./assests/theatre.png"),
      import("./assests/socialStudies.png"),
      import("./assests/vArt.png"),
      import("./assests/intergratedSci.png"),
      import("./assests/exercise.png"),
      import("./assests/music.png"),



      // ...
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading)
  {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <RingLoader color="#eeb92b" loading={true} size={100} />
        <p style={{ marginTop: 30, fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
          Loading the wonders of education. Sit tight,
          we're preparing something amazing!
        </p>
      </div>
    );
  }
  return (

    <div className="App">
      
      <NavBar />
      <div style={{ display: 'flex', alignItems: 'center', }}>
        <Stack sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, }} flexDirection={{ xs: 'column', md: 'row' }} alignContent={'center'}>
          <Box style={{ flex: 1, margin: 10 }}>
            <CBCMenuItem />
          </Box>
          <Box style={{ flex: 1, margin: 10 }}>
            <HustlerMenuItem />
          </Box>
          <Box style={{ flex: 1, margin: 10 }}>
            <SkillDatabase />
          </Box>
        </Stack>
      </div>
    </div >

  );
}

export default App;
