import React, { useState, useEffect } from 'react';

function MemberValidationComponent() {
  const [memberNumber, setMemberNumber] = useState('0100120B');
  const [hash, setHash] = useState('be85f67e43b83dd73b1cda713bf620942a812db48a012ea8eb8fdf5f7760cdc4');
  const [validationResult, setValidationResult] = useState(null);

  useEffect(() => {
    // Fetch the validation result when the component mounts (you can trigger it with a button click if needed)
    handleValidation();
  }, []);

  const handleValidation = async () => {
    const apiUrl = "https://197.248.173.27/apisrv/index.";

    const requestBody = {
      MemberNumber: memberNumber,
      Hash: hash,
    };

    try
    {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),

      });

      if (response.ok)
      {
        const data = await response.json();
        setValidationResult(data);
      } else
      {
        // Handle error response here
        console.error('Validation failed:', response.statusText);
      }
    } catch (error)
    {
      console.error('Error during validation:', error);
    }
  };

  return (
    <div>
      <h1>Member Validation</h1>
      <label>
        Member Number:
        <input
          type="text"
          value={memberNumber}
          onChange={(e) => setMemberNumber(e.target.value)}
        />
      </label>
      <label>
        Hash:
        <input
          type="text"
          value={hash}
          onChange={(e) => setHash(e.target.value)}
        />
      </label>
      <button onClick={handleValidation}>Validate</button>
      {validationResult && (
        <div>
          <h2>Validation Result:</h2>
          <pre>{JSON.stringify(validationResult, null, 2)}</pre>
          {validationResult.Photourl && (
            <img src={validationResult.Photourl} alt="Member Photo" />
          )}
        </div>
      )}
    </div>
  );
}

export default MemberValidationComponent;
