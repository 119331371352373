import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab, TextField, Grid, Stack, Divider, IconButton, Backdrop, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import emailjs from 'emailjs-com';
import contactUs from '../assests/contactUs.jpg'
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800, // Increase the width to accommodate the image
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const fabStyle = {
    position: 'fixed',
    top: '200px',
    right: '16px',
};

const bthStyNavbar = {
    color: 'black',
    padding: 1,
    margin: 1,
    border: '2px solid #edb82a00',
    '&:hover': {
        border: '2px solid #edb82a',
    },
    '&:active': {
        borderBottom: '2px solid #edb82a',
    },
};

const imageStyle = {
    width: '350px', // Set the width of the image to 100%
    borderRadius: '8px', // Add border radius for a rounded look
};

export default function ContactUsPage() {
    const formRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const firstName = e.target.firstName.value;
        const lastName = e.target.lastName.value;
        const email = e.target.email.value;
        const message = e.target.message.value;

        try
        {
            // Make a POST request to the server
            await axios.post('/contactForm', {
                firstName,
                lastName,
                email,
                message,
            }).then((res) => {

                console.log(res)
                if (res.status === 200)
                {
                    setLoading(false)
                    window.alert('Thank you for reaching out! Your inquiry is important to us, and we appreciate your interest.')
                    if (formRef.current)
                    {
                        formRef.current.reset();
                    }
                } else
                {
                    window.alert(res.errorMessage)

                }
            });


            // After successful submission, you can optionally show a success message or perform other actions
            console.log('Form submitted successfully');
            // Additionally, you can trigger the email verification request here if needed
            // For example, you can use emailjs or another library to send the verification email
            // Note: You might need to do this on the server side for security reasons
            // (i.e., generate and send the email token from the server)
        } catch (error)
        {
            setLoading(false)

            // Handle errors, log them, or show an error message to the user
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={bthStyNavbar}>
                Contact Us
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90vw', md: 800 },// Increase the width to accommodate the image
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Stack direction={'row'} justifyContent={{ xs: 'space-between', sm: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'}>
                            Contact Us
                        </Typography>
                        <IconButton onClick={handleClose} sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <ClearIcon></ClearIcon>
                        </IconButton>
                    </Stack>
                    <br></br>

                    <Divider></Divider>
                    <br></br>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
                        <img
                            src={contactUs} // Replace with the path to your image
                            alt="Contact Us"
                            style={imageStyle}

                        />
                        <form onSubmit={handleSubmit} ref={formRef}>
                            <Grid container spacing={2} sm={12}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="firstName"
                                        label="First Name"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="lastName"
                                        label="Last Name"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="email"
                                        type="email"
                                        label="Email"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="message"
                                        label="Message"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <Button type="submit" variant="contained" color="primary" sx={{ m: 'auto' }} fullWidth>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Stack>
                </Box>
            </Modal>
        </div >
    );
}
