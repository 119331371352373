import { Box, Card, CardContent, CardMedia, Divider, Grid, Stack, Tab, Tabs, Typography, Select, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import NavBar from '../components/NavBar'
import '../App.css'
import rulerBG from '../assests/rulerBG.png'
import agriIcon from '../assests/agriIcon.png'
import arabicIcon from '../assests/arabic.png'
import artIcon from '../assests/artIcon.png'
import eng from '../assests/eng.png'
import french from '../assests/french.png'
import german from '../assests/german.png'
import TwoIcon from '../assests/TwoIcon.png'
import ThreeIcon from '../assests/ThreeIcon.png'
import FourIcon from '../assests/FourIcon.png'
import FiveIcon from '../assests/FiveIcon.png'
import SixIcon from '../assests/SixIcon.png'
import SevenIcon from '../assests/SevenIcon.png'
import EightIcon from '../assests/EightIcon.png'
import NineIcon from '../assests/NineIcon.png'

import OneIcon from '../assests/OneIcon.png'
import bible from '../assests/bible.png'

import CircularProgress from '@mui/material/CircularProgress';
import comSciIcon from '../assests/comSciIcon.png'
import bsICon from '../assests/bsICon.png'
import chin from '../assests/chin.png'
import healthEdu from '../assests/healthEdu.png'
import hindu from '../assests/hindu.png'
import homeScience from '../assests/homeScience.png'
import lifeSkills from '../assests/life-skills.png'
import quran from '../assests/quran.png'
import signLanguage from '../assests/sign-language.png'
import swahili from '../assests/swahili.png'
import indegenous from '../assests/indegenous.png'
import math from '../assests/math.png'
import theatre from '../assests/theatre.png'
import socialStudies from '../assests/socialStudies.png'
import vArt from '../assests/vArt.png'
import intergratedSci from '../assests/intergratedSci.png'
import exercise from '../assests/exercise.png'
import music from '../assests/music.png'
import technical from '../assests/technicalpng.png'
import tScience from '../assests/tScience.png'
import { Link } from 'react-router-dom'
import subjectWiseObjectList from '../assests/SubjectData.js'
const categories = [
  { value: 'all', label: 'All Books' },
  { value: 'business', label: 'Business' },
  { value: 'startup', label: 'Startup' },
  { value: 'entrepreneurship', label: 'Entrepreneurship' },
  // ... add more categories as needed
];



const gradeList = [
  {
    "id": 1,
    "name": "Grade 1",
    "icon": OneIcon
  },
  {
    "id": 2,
    "name": "Grade 2",
    "icon": TwoIcon
  },
  {
    "id": 3,
    "name": "Grade 3",
    "icon": ThreeIcon
  },
  {
    "id": 4,
    "name": "Grade 4",
    "icon": FourIcon
  },
  {
    "id": 5,
    "name": "Grade 5",
    "icon": FiveIcon
  },
  {
    "id": 6,
    "name": "Grade 6",
    "icon": SixIcon
  },
  {
    "id": 7,
    "name": "Grade 7",
    "icon": SevenIcon
  },
  {
    "id": 8,
    "name": "Grade 8",
    "icon": EightIcon
  },
  {
    "id": 9,
    "name": "Grade 9",
    "icon": NineIcon
  }
  // ,
  // {
  //     "id": 9,
  //     "name": "Grade 10",
  //     "icon": TenIcon
  // },
  // {
  //     "id": 10,
  //     "name": "Grade 11",
  //     "icon": ElevenIcon
  // },
  // {
  //     "id": 11,
  //     "name": "Grade 12",
  //     "icon": TwelveIcon
  // }
]
export default function CbcMainPage() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGrade, setSelectedGrade] = useState(1); // Set initial grade to the first grade in the list
  const [subjectsForSelectedGrade, setSubjectsForSelectedGrade] = useState(subjectWiseObjectList[selectedGrade]);


  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleGradeChange = (event, newValue) => {
    console.log('Selected Grade:', newValue);
    setSelectedGrade(newValue);

    // Ensure the selected grade exists in the subjectWiseObjectList
    if (subjectWiseObjectList[newValue])
    {
      setSubjectsForSelectedGrade(subjectWiseObjectList[newValue]);
    } else
    {
      // Handle the case where the selected grade doesn't exist in the object list
      console.error(`Subjects not found for Grade ${newValue}`);
      setSubjectsForSelectedGrade([]);
    }
  };
  // const filteredCards = sy.filter((card) => {
  //   if (selectedCategory === 'all')
  //   {
  //     return true; // Show all cards if "All Books" is selected
  //   } else
  //   {
  //     return card.category === selectedCategory; // Filter by category
  //   }
  // });


  return (
    <Box className={selectedGrade === 1 || selectedGrade === 2 || selectedGrade === 3 ? "AppCBC" : "App"}>
      <NavBar></NavBar>
      <br></br>
      <br></br>
      <Select
        value={selectedGrade}
        onChange={(event) => handleGradeChange(event, event.target.value)} // Pass the numeric grade ID
        displayEmpty
        inputProps={{ 'aria-label': 'Grade' }}
        sx={{ width: '80%', display: { xs: 'block', md: 'none' }, margin: 'auto', background: '#495492', color: 'white' }}
      >
        {gradeList.map((grade) => (
          <MenuItem key={grade.id} value={grade.id}>
            {grade.name}
          </MenuItem>
        ))}
      </Select>
      <Stack direction="row" padding={8} justifyContent={'center'}>

        {/* Grade Tabs */}
        <Tabs
          value={selectedGrade}
          onChange={handleGradeChange}
          aria-label="grade tabs"
          orientation="vertical"
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            padding: 2,
            background: '#495492',
            borderRadius: 8,
            width: '20%',
            height: '100%',
            display: { xs: 'none', md: 'block' },
          }}
        >
          {gradeList.map((grade) => (
            // <div style={{ margin: 'auto', width: '100%' }} key={grade.id}>
            <Tab
              key={grade.id}
              label={grade.name}
              id={grade.id}
              value={grade.id}
              sx={{
                color: 'white',

                '&:hover': {
                  backgroundColor: 'lightblue',
                  color: 'black',
                },
              }}
            />
            //<Divider />
            // </div>
          ))}
        </Tabs>
        <div style={{ width: '80%', }}>
          {gradeList.map((grade) => (
            <Box
              key={grade.id}
              //role="tabpanel"
              hidden={selectedGrade !== grade.id}
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* Subject Cards */}
              {selectedGrade === grade.id && (
                <Grid container spacing={{ xs: 6, md: 2 }} className="card-container" justifyContent={'center'}>
                  {subjectsForSelectedGrade.map((subject) => (
                    <Grid item xs={4} sm={6} md={3} key={subject.subId}>

                      <Link to='/CoursePage' state={{ subjectName: subject.subName, subjectIcon: subject.subIcon, gradeId: selectedGrade, subCover: subject.subCover, subOutline: subject.subOutline, subBook: subject.subBook, navFlag: 0 }} style={{ textDecoration: 'none', color: 'black', fontSize: 12 }}>
                        <Stack justifyContent="center" alignItems="center">
                          <img src={subject.subIcon} alt="File Icon" style={{ width: '60px' }} />
                          {subject.subName}
                        </Stack>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          ))}
        </div>
      </Stack>
    </Box>
  );
}