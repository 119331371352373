import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';

import kalasikLogo from '../assests/KalasikLogo.png'
import { Button, Divider, IconButton, Stack, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import ContactUsPage from '../pages/ContactUsPage';
import AboutUsPageModal from '../pages/AboutUsPageModal';

import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';


function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};
const bthStyNavbar = {
    color: 'black',
    padding: 1,
    margin: 1,
    border: '2px solid #edb82a00',
    '&:hover': {
        border: '2px solid #edb82a'
    },
    '&:active': {
        borderBottom: '2px solid #edb82a'
    },

}

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

export default function NavBar(props) {
    const { window, navFlag } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>

            <Link to='/' style={{ textDecoration: 'none', color: '#534635' }}>
                <img src={kalasikLogo} width={80} />

                <Typography variant="h6" sx={{ my: 2 }}>
                    KALASIK
                </Typography>
            </Link>

            <Divider />
            <List>
                <Link to='/aiLobby' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center', backgroundColor: '#eeb92bs' }}>
                            <ListItemText primary='Ai Lobby' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>

                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Home' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding sx={{
                        display: 'flex',
                        justifyContent: 'center',  // Center horizontally
                        alignItems: 'center',     // Center vertically
                    }}>

                        <AboutUsPageModal />
                    </ListItem>
                </Link>
                <Link to='/CbcMainPage' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='CBC' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/hustlerPlaybook' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Hustlers playbook' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to='/skills' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Skills' />
                        </ListItemButton>
                    </ListItem>
                </Link>
                {/* <Link to='/solarSchool' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary='Solar Schools' />
                        </ListItemButton>
                    </ListItem>
                </Link> */}


                <Link to='/' style={{ textDecoration: 'none', color: '#534635' }}>
                    <ListItem disablePadding sx={{
                        display: 'flex',
                        justifyContent: 'center',  // Center horizontally
                        alignItems: 'center',     // Center vertically
                    }}>
                        <ContactUsPage />

                    </ListItem>
                </Link>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <React.Fragment >
            <CssBaseline />
            <ElevationScroll {...props} id="navbar">
                <AppBar sx={{ backgroundColor: 'transparent', color: '#eeb92b', width: '100vw' }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, color: 'black' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Link to='/'>
                            <img src={kalasikLogo} style={{ maxWidth: '80px' }}></img>
                        </Link>
                        <Typography sx={{ fontSize: { xs: '16px', md: '2.5vw' }, marginLeft: 1, fontWeight: 'bold', fontFamily: 'Oswald' }}>KALASIK</Typography>
                        <Grid container justifyContent={'flex-end'} alignItems={'start'} sx={{ margin: 0, padding: 0 }}>

                            <Grid item md={3} sx={{
                                backgroundColor: '#5e5dbb', height: '70px', display: 'flex', justifyContent: 'start', alignItems: 'center', borderBottomLeftRadius: '20px', marginRight: '-20px'
                            }}>
                                <Link to={navFlag == 0 ? '/CbcMainPage' : '/skills'} ><Button sx={{
                                    marginLeft: { xs: 0, md: 2 },
                                    marginRight: { xs: 1, md: 0 },
                                    color: 'white',
                                    fontSize: { xs: 20, md: 35 },
                                    fontWeight: 'bold',
                                    fontFamily: 'Oswald',
                                }}>{navFlag == 0 ? 'CBC' : 'SKILLS'}</Button></Link>
                            </Grid>
                            {
                                props.navFlag == 0 ?
                                    <Grid item md={4} sx={{ backgroundColor: '#fed32e', height: '70px', display: 'flex', justifyContent: 'start', alignItems: 'center', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                        <Link to='/'><Button sx={{
                                            marginLeft: 2,
                                            color: 'white',
                                            fontSize: { xs: 20, md: 35 },
                                            fontWeight: 'bold',
                                            fontFamily: 'Oswald',
                                        }}>Grade {props.gid}</Button></Link>
                                    </Grid> : <div></div>
                            }

                        </Grid>

                        {/* <Stack sx={{ marginLeft: '5vw' }} direction={'row'} spacing={2}>
                            <Link to='/'><Button >CBC</Button></Link>
                            <Link to='/'><Button >Home</Button></Link>
                        </Stack> */}
                    </Toolbar>
                </AppBar>

            </ElevationScroll>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Toolbar />
        </React.Fragment>
    );
}
